import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import * as echarts from "echarts";
import { DashboardService } from 'src/app/services/dashboard.service';
import { CdkOverlyayService } from '../custom-overlay/cdk-overlyay.service';
import { TemplateDropdownComponent } from '../custom-overlay/template-dropdown/template-dropdown.component';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { formatDate, KeyValue } from '@angular/common';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValidatorFn, FormGroup, ValidationErrors, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { ErrorMsgHandler } from 'src/app/shared/errormanagement/handleerror';
import { TimeZoneService } from 'src/app/services/time-zone.service';
import * as moment from 'moment-timezone';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss']
})
/*
  according to the widget type and name sent from parent component 
  from_date from metadata and to_date as current_date - data is populated and widget is rendered
  chart render options and data are get from api and combined here
*/
/*
---values sent from parent component(dashboard/studio preview)
widgetType(table/chart),widget name  
dialogWidth - width % of the full screen dialog
moreOptionEnable - in studio preview, more options should not be shown
---variables used
widgetDetail - data and otherdetails of widget subscribed from api
chartOption -  to build echart
selectedColorPalette - selected colors, to restore that color after date filter is applied
moreOptionState -  show/hide more options(dayfiter,colorpalatee, dull screen and download)
fromDate - date filter from date - default value
toDate - date filter to date - default value
*/
export class DashboardWidgetComponent implements OnInit, OnDestroy {

  handledashboardwidgetnodata = new ErrorMsgHandler()
  errorhandledashboardwidget = new ErrorMsgHandler()

  // details from parent component
  @Input() widgetType: any;
  @Input() widgetName: string;
  @Input() widgetImg: string;
  @Input() dialogWidth: any;
  @Input() moreOptionEnable: boolean;
  @Input() CustomFilterSub: string;
  @Input() filterDays: number;
  @Input() defaultDate: any;
  selectedColorIndex:number = 10
  widgetDetail: any;
  chartOption: any;
  selectedColorPalette: string;
  moreOptionState = false;
  defaultColors = this.loginService.metaData.dashboard.dashboard_landing.colors
  moreoptiontitles = this.loginService.metaData.dashboard.dashboard_landing.widgetmenu

  customTimeRange = {
    from: "",
    to: "",
    valid: false
  }

  maxdate = this.timeZoneService.getcurrentuserTime().format(this.dashboardService.timeformat)
  mindate = this.timeZoneService.toUserTimeZone(this.loginService.timerange.event_time_range[0]).format('YYYY-MM-DD HH:mm:ss');

  defaultTimeRange = {
    from: this.timeZoneService.getcurrentuserTime().clone().startOf('D').format('YYYY-MM-DD HH:mm'),
    to: this.timeZoneService.getcurrentuserTime().format('YYYY-MM-DD HH:mm')
  }


  timeRange = this.fb.group({
    fromDate: new UntypedFormControl(this.defaultTimeRange.from),
    toDate: new UntypedFormControl(this.defaultTimeRange.to),
  }, { validators: [this.creatDateRangeValidator()] });

  selectTime = this.fb.group({
    fromDate: new UntypedFormControl(this.defaultTimeRange.from),
    toDate: new UntypedFormControl(this.defaultTimeRange.to),
  }, { validators: [this.creatDateRangeValidator()] });


  dialogRef: any;
  previewRef: any;
  widgetRequestObj = {
    'hosts': [''],
    'default': true,
    'widget_type': '',
    'widget_name': '',
    'timeRange': ['']
  }
  defaultState: boolean = false;
  spinnerState: boolean = false;
  $destroy: Subject<boolean> = new Subject();

  widgetrequestinstance: any;

  constructor(public matDialog: MatDialog,
    public cdkverlayDialog: CdkOverlyayService, private cdf: ChangeDetectorRef, private fb: UntypedFormBuilder,
    private dashboardService: DashboardService, private loginService: UserLoginService,
    private timeZoneService: TimeZoneService
  ) { }

  ngOnInit(): void {
    this.setTimeRange(this.loginService.metaData.dashboard.dateFilter_default.default_fromDt);
    this.defaultState = true;
    this.resetdateinput();

    this.dashboardService.getdashboardlanding.isProgress$.pipe(takeUntil(this.$destroy)).subscribe(progress => {
      if (!progress) {
        if (this.dashboardService.dashboardLandingData['isData']) {
          this.setWidgetDetails(this.dashboardService.dashboardLandingData['res_obj'][this.widgetName])
        } else {
          this.setErrormsg(this.dashboardService.dashboardLandingData)
        }
      } else {
        this.spinnerState = true
      }
    })
  }
  //used in .html, to display the keys of an object as  the order it comes from api
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }
  creatDateRangeValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const err = {}
      const start = this.timeZoneService.setcurrentuserTime(form.get("fromDate").value);
      const end = this.timeZoneService.setcurrentuserTime(form.get("toDate").value);

      if (start && end) {
        const validyear = start.year() > 2000 && end.year() > 2000;
        if (!validyear) {
          err['yearrange'] = true;
        }
        const isRangeValid = (end.unix() - start.unix() > 0) && (end || start) < this.timeZoneService.getcurrentuserTime();

        if (!isRangeValid) {
          err['dateRange'] = true;
        }
      }
      return err;
    }
  }

  resetdateinput() {
    this.selectTime.controls.fromDate.reset(this.defaultTimeRange.from);
    this.selectTime.controls.toDate.reset(this.defaultTimeRange.to);
    this.customTimeRange.valid = false
  }
  //load widget details, chartOption and chart data according to from and to dates
  load_widgetData(loadDataType, noOfDays?, clicktype?) {
    this.defaultState = false;
    this.widgetRequestObj["refresh"] = false;
    //set from date, to date according to the selected option like yesterday / last 7 days....
    if (clicktype && clicktype == 'relativetime') {
      this.resetdateinput();
    }

    this.setTimeRange(noOfDays);
    if (!this.timeRange.errors) {
      if (clicktype && clicktype == 'customtime') {
        this.customTimeRange.from = this.selectTime.controls.fromDate.value;
        this.customTimeRange.to = this.selectTime.controls.toDate.value;
        this.customTimeRange.valid = true
      }

      this.handledashboardwidgetnodata.status = false;
      this.errorhandledashboardwidget.status = false;
      if (loadDataType == "refresh") {
        this.resetdateinput();
        this.selectedColorPalette = ""
        this.widgetRequestObj["refresh"] = true;
        this.widgetRequestObj["widget_key"] = this.widgetName
      } else {
        this.widgetRequestObj["widget_key"] = this.widgetName + this.timeZoneService.toUtcTimeZonefromUser(this.timeRange.controls.toDate.value).format('YYYY-MM-DD')
      }

      if (this.previewRef) {
        this.previewRef.close()
      }
      if (this.dialogRef) {
        this.dialogRef.close()
      }
      if (this.selectTime.errors) return
      this.widgetDetail = null;
      this.widgetRequestObj['widget_type'] = this.widgetType
      this.widgetRequestObj['widget_name'] = this.widgetName;
      this.widgetRequestObj['hosts'] = ['all']

      this.widgetRequestObj['timeRange'] = [this.timeZoneService.toUtcTimeZonefromUser(this.timeRange.controls.fromDate.value).format(this.dashboardService.timeformat), this.timeZoneService.toUtcTimeZonefromUser(this.timeRange.controls.toDate.value).format(this.dashboardService.timeformat)]


      // Table widget
      if (this.widgetType == 'Table') {
        this.spinnerState = true
        this.widgetrequestinstance = this.dashboardService.getwidgetTable()
        this.widgetrequestinstance.postrequest(this.widgetRequestObj)
          .pipe(takeUntil(this.$destroy))
          .subscribe(val => {
            this.setWidgetDetails(val);
          },
            (err) => {
              // this.spinnerState = false;
              this.setErrormsg(err);
            },
            () => {
              this.spinnerState = false;
            });

      }
      // Chart widget
      if (this.widgetType == 'Chart') {
        this.chartOption = {};
        this.spinnerState = true;
        this.widgetrequestinstance = this.dashboardService.getwidgetchart();
        this.widgetrequestinstance.postrequest(this.widgetRequestObj)
          .pipe(takeUntil(this.$destroy))
          .subscribe((val: any) => {
            this.setWidgetDetails(val)
          },
            (err) => {
              this.setErrormsg(err);
            },
            () => {
              this.spinnerState = false;
            }
          );
      }
    }
  }

  //set timerange or form control
  setTimeRange(noOfDays: any) {
    if (noOfDays && noOfDays[2] >= 0) {
      this.filterDays = noOfDays[2];
      this.timeRange.controls.fromDate.patchValue(this.dashboardService.timeRangeCalc(noOfDays)[0]);
      this.timeRange.controls.toDate.patchValue(this.dashboardService.timeRangeCalc(noOfDays)[1]);
    } else {
      this.filterDays = undefined;
      this.timeRange.controls.fromDate.patchValue(this.timeZoneService.setcurrentuserTime(this.selectTime.controls.fromDate.value).format('YYYY-MM-DD HH:mm'));
      this.timeRange.controls.toDate.patchValue(this.timeZoneService.setcurrentuserTime(this.selectTime.controls.toDate.value).format('YYYY-MM-DD HH:mm'));
    }
  }
  // for open & close more option click
  moreOptions_click() {
    this.moreOptionState = true // !this.moreOptionState;
  }
  closeOptions_click() {
    if (this.dialogRef) {
      this.dialogRef.close()
    }
  }
  //open moreoptions in overlay

  openOverlay_moreoptions_click(template, origin): void {
    this.moreOptionState = true
    let config = {
      width: "225px",
      height: "29px",
      data: {

        "template": template,
      }
    }
    this.dialogRef = this.cdkverlayDialog.dashboard_moreoptions(config, TemplateDropdownComponent, origin);
  }
  //open color palatte and datefilter in overlay
  openOverlay_click(template, origin, overlaytype): void {
    if (!(this.widgetType == 'Table' && overlaytype == 'colorpalate')) {
      if (!this.handledashboardwidgetnodata.status || overlaytype == "datefilter") {
        let config = {
          width: '5%',
          // backdropClass: 'cdk-overlay-dark-backdrop',
          data: {

            "template": template,
          }
        }
        this.previewRef = this.cdkverlayDialog.openPreview(config, TemplateDropdownComponent, origin);

        if (!this.customTimeRange.valid) {
          this.resetdateinput();
        }

        this.previewRef.$closed.subscribe(() => {
          if (this.selectTime.errors) {
            this.resetdateinput();
          }
        })
      }
    }
  }
  //updates chart color for full screen option
  colorPalatte_click(selectedColor,index:number) {
    this.selectedColorIndex=index
    const chartDom = document.getElementById(this.widgetName);
    const myChart = echarts.init(chartDom);
    let options = myChart.getOption();
    if (this.widgetDetail.chart_type == 'custompolarheatmap') {
      // selectedColor = ['#FFFFFF'].concat(selectedColor);
      myChart.setOption({
        visualMap: {
          inRange: {
            color: selectedColor
          }
        }
      });
      this.chartOption.visualMap.inRange.color = selectedColor;
    } else if (this.widgetDetail.chart_type == 'polarbar') {
      options.series[1].itemStyle.color = selectedColor[5];
      options.series[3].itemStyle.color = selectedColor[3];
      myChart.setOption(options);
      this.chartOption = options;
    } else if (this.widgetDetail.chart_type == 'large_area_chart') {
      options.series[0].itemStyle.color = selectedColor[5];
      // options.series[0].areaStyle.color = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //   {
      //     offset: 0,
      //     color: selectedColor[2]
      //   },
      //   {
      //     offset: 1,
      //     color: selectedColor[0]
      //   }
      // ])
      options.series[0].areaStyle.color = selectedColor[0];
      myChart.setOption(options);
      this.chartOption = options;
    }
    else if (this.widgetDetail.chart_type == 'treemap') {
      const sc = [...selectedColor];
      // options.series[0].levels[0].color = sc.reverse()
      options.series[0].levels[0].color = sc
      myChart.setOption(options);
      this.chartOption = options;
    }
    else {
      myChart.setOption({
        color: selectedColor
      });
    }

    // this is to update the chartoption object, so that it will be reflected in fullscreen
    if (selectedColor) this.chartOption.color = selectedColor
    this.selectedColorPalette = selectedColor; // store selected color apply again after date filter is applied
  }


  setChartColor(selectedColor) {
    if (this.widgetDetail.chart_type == 'custompolarheatmap') {
      // selectedColor = ['#FFFFFF'].concat(selectedColor);
      this.chartOption.visualMap.inRange.color = selectedColor;
    }
    else if (this.widgetDetail.chart_type == 'polarbar') {
      this.chartOption.series[1].itemStyle.color = selectedColor[5];
      this.chartOption.series[3].itemStyle.color = selectedColor[3];
    }
    else if (this.widgetDetail.chart_type == 'large_area_chart') {
      this.chartOption.series[0].itemStyle.color = selectedColor[0];
      // this.chartOption.series[0].areaStyle.color = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      //   {
      //     offset: 0,
      //     color: selectedColor[2]
      //   },
      //   {
      //     offset: 1,
      //     color: selectedColor[0]
      //   }
      // ])
      this.chartOption.series[0].areaStyle.color = selectedColor[1];
    }
    else if (this.widgetDetail.chart_type == 'treemap') {
      const sc = [...selectedColor];
      // this.chartOption.series[0].levels[0].color = sc.reverse()
      this.chartOption.series[0].levels[0].color = sc
    }
    else {
      this.chartOption.color = selectedColor
    }
  }

  //open full screen option
  fullscreen_click(header, body, subTitle): void {
    if (!this.handledashboardwidgetnodata.status) {
      this.matDialog.open(DialogBoxComponent, {
        width: this.dialogWidth,
        height: '80vh',
        data: {
          template: body,
          head: header,
          subHead: subTitle,
        }
      });
    }
  }
  //download table/chart data option
  download_click(): void {
    if (!this.handledashboardwidgetnodata.status) {
      this.moreOptionState = false; //close the moreoption div before downloading
      this.cdf.detectChanges(); // it stop executing the next lines untill the change is ddtected

      if (this.widgetType == "Table") {
        const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
        doc.autoTable({
          theme: 'plain',
          headStyles: { fillColor: '#E8E8E8' },
          head: [this.widgetDetail.tableHeaders],
          body: this.widgetDetail.tableRows.map(Object.values)
        });
        doc.save('table.pdf');
      }
      else {
        let DATA: any = document.getElementById(this.widgetName + 'htmlData'); //full widget with title and more filter imagej 
        html2canvas(DATA).then((canvas) => {
          let fileWidth = 200;
          let fileHeight = (canvas.height * fileWidth) / canvas.width;
          const FILEURI = canvas.toDataURL('image/png');
          let PDF = new jsPDF('p', 'mm', 'a4');
          let position = 25;
          PDF.addImage(FILEURI, 'PNG', 5, position, fileWidth, fileHeight);
          PDF.save('chart.pdf');
        });
      }
    }
  }

  customSubtitle(sub: string, fromdate, todate, numDays) {
    if (sub) {
      switch (true) {
        case (numDays == undefined || (fromdate && todate)):
          this.CustomFilterSub = sub + ` ` + `${formatDate(fromdate, 'dd-MM-yyyy', 'en-US')} to ${formatDate(todate, 'dd-MM-yyyy', 'en-US')}`;
          break;
        case (numDays == 1):
          this.CustomFilterSub = sub + ` ` + ` yesterday`;
          break;
        case (numDays == 0):
          this.CustomFilterSub = sub + ` ` + ` today`;
          break;
        default:
          this.CustomFilterSub = sub + ` ` + ` past ${numDays + 1} days`;
      }

    }
  }
  format_axiz_lable(option) {
    let temp_option = {}
    if ('yAxis' in option && 'type' in option['yAxis'] && option['yAxis']['type'] == 'value') {
      temp_option = {
        ...option, ...{
          'yAxis': {
            'axisLabel': {
              'formatter': function (value) {
                if (value >= 1000000000) {
                  return echarts.format.addCommas((value / 1000000000).toFixed(1)) + ' B';
                } else if (value >= 1000000) {
                  return echarts.format.addCommas((value / 1000000).toFixed(1)) + ' M';
                } else if (value >= 1000) {
                  return echarts.format.addCommas((value / 1000).toFixed(1)) + ' K';
                } else {
                  return echarts.format.addCommas(value);
                }

              }
            }
          }
        }
      }
      return temp_option
    }
    else if ('angleAxis' in option && 'type' in option['angleAxis'] && option['angleAxis']['type'] == 'value') {
      temp_option = {
        ...option, ...{
          'angleAxis': {
            'axisLabel': {
              'formatter': function (value) {
                if (value >= 1000000000) {
                  return echarts.format.addCommas((value / 1000000000).toFixed(1)) + ' B';
                } else if (value >= 1000000) {
                  return echarts.format.addCommas((value / 1000000).toFixed(1)) + ' M';
                } else if (value >= 1000) {
                  return echarts.format.addCommas((value / 1000).toFixed(1)) + ' K';
                } else {
                  return echarts.format.addCommas(value);
                }

              }
            },
            'axisLine': {
              'lineStyle': {
                'opacity': 0.2
              }
            }
          }
        }
      }
      return temp_option;
    }
    else if ('xAxis' in option && 'type' in option['xAxis'] && option['xAxis']['type'] == 'value') {
      temp_option = {
        ...option, ...{
          'xAxis': {
            'axisLabel': {
              'formatter': function (value) {
                if (value >= 1000000000) {
                  return echarts.format.addCommas((value / 1000000000).toFixed(1)) + ' B';
                } else if (value >= 1000000) {
                  return echarts.format.addCommas((value / 1000000).toFixed(1)) + ' M';
                } else if (value >= 1000) {
                  return echarts.format.addCommas((value / 1000).toFixed(1)) + ' K';
                } else {
                  return echarts.format.addCommas(value);
                }

              }
            }
          }
        }
      }
      return temp_option;
    }
    else return option;
  }
  setWidgetDetails(widgetdetails: any) {
    if (widgetdetails && widgetdetails.hasOwnProperty('widgetDetails')) {
      if (this.widgetType == 'Chart') {
        this.chartOption = {};
        this.widgetDetail = widgetdetails.widgetDetails;
        this.customSubtitle(this.widgetDetail.subTitle, this.timeRange.controls.fromDate.value, this.timeRange.controls.toDate.value, this.filterDays)
        if (this.widgetDetail.isData) {
          this.chartOption = this.format_axiz_lable(this.widgetDetail.chartOption);
          if (this.selectedColorPalette) {
            this.setChartColor(this.selectedColorPalette);
          } else {
            this.setChartColor(this.defaultColors);
          }
          this.spinnerState = false;
          if (this.widgetDetail.chart_type == 'pie') {
            if (this.widgetDetail.chartData) this.chartOption.series[0].data = this.widgetDetail.chartData;
            else this.chartOption.series[0].data = [];


          } else if (this.widgetDetail.chart_type == 'line') {
            if (this.widgetDetail.chartData) {

              let alertcountchartinfo = this.dashboardService.getAlertCountChartdata(this.widgetDetail.chartData['data'])
              this.chartOption.xAxis.data = alertcountchartinfo['alert_days'];
              this.chartOption.series[0]['data'] = alertcountchartinfo['alert_cnt'];
            }
          } else if (this.widgetDetail.chart_type == 'custompolarheatmap') {

            if (this.widgetDetail.chartData) {

              let addchartinfo = this.dashboardService.getCustomPolarHeatmapChartdata(this.widgetDetail.chartData['data']);
              this.chartOption.visualMap.max = addchartinfo['maxvalue'];
              this.chartOption.series[0].renderItem = addchartinfo['renderitem'];
              this.chartOption.angleAxis.data = this.widgetDetail.chartData['hours'];
              this.chartOption.radiusAxis.data = addchartinfo['days'];
              this.chartOption.series[0].data = addchartinfo['data'];
              this.chartOption.tooltip.formatter = this.dashboardService.getcustomPolarheatmapChartformatter(addchartinfo['days'], addchartinfo['data']);

            }
          } else if (this.widgetDetail.chart_type == 'polarbar') {
            console.log(this.widgetDetail)
            if (this.widgetDetail.chartData) {
              if (this.widgetDetail.chartData['data']) {
                this.chartOption.angleAxis.data = this.widgetDetail.chartData['eventids'];
                this.chartOption.tooltip.formatter = this.dashboardService.getPolarBarChartformatter(this.widgetDetail.chartData['data'], this.widgetDetail.chartData['eventids']);
                this.chartOption.series = this.dashboardService.getPolarBarChartSeries(this.chartOption.series, this.widgetDetail.chartData['data']);
              }
            }
          } else if (this.widgetDetail.chart_type == 'treemap') {
            if (this.widgetDetail.chartData) {
              let formaterfn = this.dashboardService.getTreemapChartformater();
              this.chartOption.tooltip.formatter = formaterfn;
              this.chartOption.series[0].data = this.widgetDetail.chartData;
            }
          }
        } else {
          this.spinnerState = false;
          this.handledashboardwidgetnodata.seterror(true, this.loginService.metaData.alert.noDatamsg[1])
        }

      } else if (this.widgetType == 'Table') {
        this.widgetDetail = widgetdetails.widgetDetails
        this.spinnerState = false;
        this.customSubtitle(this.widgetDetail.subTitle, this.timeRange.controls.fromDate.value, this.timeRange.controls.toDate.value, this.filterDays)
        if (!this.widgetDetail.isData) {
          this.handledashboardwidgetnodata.seterror(true, this.loginService.metaData.alert.noDatamsg[1])
        }
      }
    } else if (widgetdetails && widgetdetails.hasOwnProperty('isError') && widgetdetails.hasOwnProperty('error')) {
      this.setErrormsg(widgetdetails)
    }

  }

  setErrormsg(err) {
    this.spinnerState = false;
    this.errorhandledashboardwidget.seterror(true, err.error?.errmsg);
  }


  ngOnDestroy() {
    if (this.previewRef) this.previewRef.close();
    if (this.dialogRef) this.dialogRef.close();
    if (!this.defaultState) {
      this.widgetrequestinstance.cancelRequest();
    }
    this.$destroy.next(true)
    this.$destroy.unsubscribe()
  }

} 