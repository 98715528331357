// import { Directive, ElementRef, Input, OnInit } from '@angular/core';

// @Directive({
//   selector: '[LimitSpanContent]', // Directive selector
// })
// export class LimitSpanContentDirective implements OnInit {
//   @Input() limit: number; // Input for character limit

//   constructor(private el: ElementRef) {}

//   ngOnInit() {
//     this.truncateText();
//   }

//   private truncateText() {
//     const container: HTMLElement = this.el.nativeElement;
//     console.log("LimitSpanContent=======",container)
//     const spans = container.querySelectorAll('span');
//     let totalCharacters = 0;
//     let truncated = false;

//     spans.forEach((span: HTMLElement) => {
//       if (truncated) {
//         span.textContent = ''; // Clear text of remaining spans
//         return;
//       }

//       const spanText = span.textContent || ''; // Ensure no null or undefined
//       const spanLength = spanText.length;

//       if (totalCharacters + spanLength > this.limit) {
//         const remaining = this.limit - totalCharacters;
//         span.textContent = spanText.slice(0, remaining) + '...'; // Truncate and append "..."
//         truncated = true;
//       } else {
//         totalCharacters += spanLength; // Accumulate character count
//       }
//     });
//   }
// }

import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[LimitSpanContent]', // Directive selector
})
export class LimitSpanContentDirective implements OnChanges {
  @Input() limit: number = 300; // Input for character limit

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['limit'] && this.limit > 0) {
      this.truncateText(); // Reapply truncation when the limit changes
    }
  }

  private truncateText(): void {
    const container: HTMLElement = this.el.nativeElement;
    const spans = container.querySelectorAll('span');
    if (!spans.length) {
      console.warn('No <span> elements found within the container.');
      return;
    }

    let totalCharacters = 0;
    let truncated = false;

    spans.forEach((span: HTMLElement) => {
      const spanText = span.textContent || ''; // Get the text content of the span
      const spanLength = spanText.length;

      if (truncated) {
        span.textContent = ''; // Clear remaining span text
        return;
      }

      if (totalCharacters + spanLength > this.limit) {
        const remaining = this.limit - totalCharacters;
        span.textContent = spanText.slice(0, remaining) + '...'; // Truncate the text and append "..."
        truncated = true;
      } else {
        totalCharacters += spanLength; // Add to total character count
      }
    });
  }
}
