<div class="add-cutomer-container">
    <div class="header">
        <h4>{{data.header}}</h4>
        <label>Service provider:<span>{{data.service_provider}}</span></label>
        <img (click)="close()" class="close-img" src="assets/close-dialog.svg" alt="close">
    </div>
    <ng-container *ngIf="mat_spinner_role_state">
        <div class="body-bottom-spinner">
            <mat-spinner class="spinner" diameter=20></mat-spinner>
        </div>
    </ng-container>
    <div class="body-wrapper" [formGroup]="addCustomerForm">
        <div class="mandatory-field">* Mandatory</div>
        <div class="body-row-1">
            <!-- <div class="service-provide-name">
                <label for="service-provide-name">
                    Service provider
                </label>
                <div class="body-top-row-1-right">
                    <div class="body-top-row-1-right-col-1">
                        <div class="input-box-type">
                            <input  type="text" autocomplete="no" [value]="data.service_provider">
                        </div>
                        <div class="alert-danger"></div>
                    </div>
                </div>
            </div> -->
            <div class="customer-name-label">
                <label for="customer_full_name">
                    {{customer_metadata_infos.labels[0]}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-1-right">
                    <div class="body-top-row-1-right-col-1">
                        <div class="input-box-type">
                            <input  type="text" autocomplete="no" [placeholder]="customer_metadata_infos.placeholder[0]" formControlName="fullname">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="(fullname.touched || submitState)">
                                <!-- Fullname is required -->
                                {{customer_metadata_infos.error.fullname|oneError: fullname.errors}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="customer-shortname-label">
                <label for="customer_short_name">
                    {{customer_metadata_infos.labels[1]}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-2-right">
                    <div class="body-top-row-2-right-col-1">
                        <div class="input-box-type">
                            <input  type="text" autocomplete="no" specialCharOmit [charList]="inputBoxEmitChar" [placeholder]="customer_metadata_infos.placeholder[1]" formControlName="shortname">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="(shortname.touched || submitState) ">
                                <!-- Shortname is required -->
                                {{customer_metadata_infos.error.shortname|oneError: shortname.errors}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="customer-domain-label">
                <label for="customer_domain_name">
                    {{customer_metadata_infos.labels[2]}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-3-right">
                    <div class="body-top-row-3-right-col-1">
                        <div class="input-box-type">
                            <input  type="text" autocomplete="no" [placeholder]="customer_metadata_infos.placeholder[2]" formControlName="domain_name">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="(domain_name.touched || submitState) ">
                                <!-- Domainname is required -->
                                {{customer_metadata_infos.error.domain_name|oneError: domain_name.errors}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body-row-2">
            <table>
                <thead>
                    <tr>
                      <ng-container *ngFor="let column of customer_metadata_infos.headers">
                        <th>{{column}}</th>
                      </ng-container>
                    </tr>
                </thead>
                <tbody >

                    <ng-container formArrayName="platforms">
                        <tr *ngFor="let row of platforms?.controls, let i=index" id="tab{{i}}" >
                            <ng-container [formGroupName]="i">
                                <td>
                                   <input type="text"  formControlName="platform_disp" > 
                                   <div class="alert-danger">
                                        <div></div>
                                    </div>
                                </td>
                                <td>
                                   <input type="checkbox"  formControlName="assigned_status" (click)="checkassign_status(i)" > 
                                   <!-- <label class="checkbox-container" >
                                    <input type="checkbox" formControlName="assigned_status" >
                                    <span class="checkmark-lite"></span> 
                                 </label> -->
                                </td>
                                <td>
                                    <input type="checkbox"  formControlName="deploy_status" (click)="checkdepoly_status(i)"> 
                                 </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="alert-danger">
            <div *ngIf="(platforms.touched || submitState) && !platforms.dirty && (!domainnameValueChangeState && !shortnameValueChangeState )">
                {{customer_metadata_infos.error.platforms.required}}
                <!-- {{customer_metadata_infos.error.platforms|oneError: platforms.errors}} -->
            </div>
        </div>


    </div>
    <div class="footer">
        <button class="cancel-button" (click)="cancel()">{{data.buttonLabels[1]}}</button>
        <button class="submit-button" (click)="onSubmitClick()">{{data.buttonLabels[0]}}</button>
    </div>
</div>
