import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { ToastService } from '../shared/custom-overlay/toast/toast.service';
import { takeUntil } from 'rxjs/operators';
import { UserLoginService } from '../user-login/user-login.service';
import { API_STATEMANAGEMENT } from '../shared/stateapimanagement/api-statemanagement';

@Injectable({
  providedIn: 'root'
})
export class SettingsService implements OnDestroy {

  $destroy: Subject<boolean> = new Subject()
  userlandingComponentDestroyed: Subject<number> = new Subject();
  rolelandingComponentDestroyed: Subject<number> = new Subject();

  constructor(
    private http: HttpClient,
    private toaster: ToastService,
    private userloginservice: UserLoginService) {

  }

  headers = new HttpHeaders({ 'content-type': 'application/json', 'charset': 'utf-8' })

  // stateObject = {
  //   isLoaded:false,
  //   tabIndex:0,
  //   userLandingPagination:{},
  //   usersCount:1,
  //   roleLandingPagination:{},
  //   rolesCount:{},
  //   fieldsLanding:{}
  // }

  // componentDestroyed:Subject<number> = new Subject()
  handledeletePagination(data, presentPage) {
    if (data.length == 1 && presentPage > 1) {
      return presentPage - 1;
    } else {
      return presentPage;
    }
  }

  checkIfUsernameExists(username: any) {
    return this.http.post(environment.apiUrl + '/settings/check/userId', JSON.stringify({ username: username }), { headers: this.headers })
  }

  getRoles(username:string) {
    return this.http.post(environment.apiUrl + '/settings/roleList/get', JSON.stringify({ username: username }), { headers: this.headers })
  }

  getUserEdit(username) {
    return this.http.post(environment.apiUrl + '/settings/editUser/get', JSON.stringify({ user_name: username }), { headers: this.headers })
  }

  postNewUser(val: any) {
    return this.http.post(environment.apiUrl + '/settings/newUser/post', JSON.stringify(val), { headers: this.headers })
  }

  postEditUser(val: any) {
    return this.http.post(environment.apiUrl + '/settings/editUser/post', JSON.stringify(val), { headers: this.headers })
  }
  deleteUser(userid: string) {
    return this.http.post(environment.apiUrl + '/settings/user/delete', JSON.stringify({ username: userid }), { headers: this.headers })
  }
  getSettingsMenu(userReq) {
    return this.http.post(environment.apiUrl + '/settings/menuList/get', JSON.stringify(userReq), { headers: this.headers })
  }
  getCustomers(username) {
    return this.http.post(environment.apiUrl + '/settings/customer/get', JSON.stringify({ username: username }), { headers: this.headers })
  }

  getPlatformList(){
    return this.http.post(environment.apiUrl + '/settings/user/platformlist/get', JSON.stringify({  }), { headers: this.headers })
  }

  provideuserlist = new API_STATEMANAGEMENT(
    this.getUserList,
    this.http,
    this.userlandingComponentDestroyed.asObservable(),
    this.userloginservice.userLoggedoff_
  )

  getUserList(userReq: any) {
    return this.http.post(environment.apiUrl + '/settings/userList/get', userReq, { headers: this.headers })
  }

  providerolelist = new API_STATEMANAGEMENT(
    this.getRoleList,
    this.http,
    this.rolelandingComponentDestroyed.asObservable(),
    this.userloginservice.userLoggedoff_
  )

  getRoleList(roleReq: any) {
    return this.http.post(environment.apiUrl + '/settings/role/list/get', roleReq, { headers: this.headers })
  }

  getroleUserList() {
    return this.http.post(environment.apiUrl + '/settings/role/userList/get', {}, { headers: this.headers })
  }

  getMenuList() {
    return this.http.post(environment.apiUrl + '/settings/role/menuList/get', {}, { headers: this.headers })
    // .pipe(takeUntil(this.$destroy))
    // .subscribe((menu) => {
    //   console.log(menu)
    // const menuArray = Object.values(menu)
    // this.settingList = menuArray.filter((val) => val.parent_menu == 'settings')
    // this.menuList = menuArray.filter((val) => val.parent_menu == 'none')
    // console.log(this.settingList);
    // console.log(this.menuList);
    // })
  }

  getRoleedit(roleName: any) {
    return this.http.post(environment.apiUrl + '/settings/role/edit/get', JSON.stringify({ role_name: roleName }), { headers: this.headers })
  }

  postRoleedit(roleObj: any) {
    return this.http.post(environment.apiUrl + '/settings/role/edit/post', JSON.stringify(roleObj), { headers: this.headers })
  }

  postNewrole(roleObj: any) {
    return this.http.post(environment.apiUrl + '/settings/newRole/post', JSON.stringify(roleObj), { headers: this.headers })
  }

  deleteRole(reqObj: any) {
    return this.http.post(environment.apiUrl + '/settings/role/delete', JSON.stringify(reqObj), { headers: this.headers })
  }

  checkRoleNameExists(roleName: any) {
    return this.http.post(environment.apiUrl + '/settings/check/roleName', JSON.stringify({ role_name: roleName }), { headers: this.headers })
  }

  fiedsDetails(platform: any) {
    return this.http.post(environment.apiUrl + '/settings/fields/get', JSON.stringify({ platform_desc: platform }), { headers: this.headers })
  }

  postFieldsDetails(fields: any) {
    return this.http.post(environment.apiUrl + '/settings/newField/post', JSON.stringify(fields), { headers: this.headers })
  }


  getCutomerPlatformList(reqObj:any){
    return this.http.post(environment.apiUrl + '/settings/customer/platforms/get', JSON.stringify(reqObj), { headers: this.headers })
  }

  postNewCustomer(reqObj:any){
    return this.http.post(environment.apiUrl + '/settings/customer/new/post', JSON.stringify(reqObj), { headers: this.headers })
  }

  getEditCutomer(reqObj:any){
    return this.http.post(environment.apiUrl + '/settings/customer/edit/get', JSON.stringify(reqObj), { headers: this.headers })
  }

  postEditCustomer(reqObj:any){
    return this.http.post(environment.apiUrl + '/settings/customer/edit/post', JSON.stringify(reqObj), { headers: this.headers })
  }

  checkCustomerNameExists(customername: any) {
    return this.http.post(environment.apiUrl + '/settings/customer/name/check', JSON.stringify({ fullname: customername }), { headers: this.headers })
  }

  getRoleCustomer(username: any){
    return this.http.post(environment.apiUrl + '/settings/role/new/get', JSON.stringify({ username: username }), { headers: this.headers })
  }

  postNewRole(reqObj:any){
    return this.http.post(environment.apiUrl + '/settings/role/new/post', JSON.stringify(reqObj), { headers: this.headers })
  }

  getEditRoleCustomer(rolename:string){
    return this.http.post(environment.apiUrl + '/settings/role/edit/get', JSON.stringify({role_name:rolename}), { headers: this.headers })
  }

  showToaster(message: string, type: any) {
    this.toaster.show({
      text: message,
      type: type
    })
  }

  ngOnDestroy(): void {
    this.$destroy.next(true)
    this.$destroy.unsubscribe()
  }

}