<div [formGroup]="userDetail" class="user-container">
    <div class="header">
        <h4>{{data.editState ? this.usermeta.headerTitle[1]:this.usermeta.headerTitle[0]}}</h4>
        <img (click)="closedialogClick()" src="assets/rule/close-dialog.svg">
    </div>
    <ng-container *ngIf="mat_spinner_user_state">
        <div class ="body-bottom-spinner">
            <mat-spinner class="spinner" diameter=20></mat-spinner>
        </div>
    </ng-container>
    <div class="body">
        <label class="highlight">{{usermeta.commonLabels[0]}}</label>
        <div class="body-top">
            <div class="body-top-row-1">
                
                <label>
                    {{usermeta.formmsg.username.label}}<span class="text-danger">*</span>/Password<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-1-right">
                    <div class="body-top-row-1-right-col-1">
                        <div class="input-box-type">
                            <input formControlName="username" type="text" autocomplete="no" specialCharOmit [charList]="inputBoxEmitChar" [placeholder]="usermeta.formmsg.username.placeholder">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="username.touched || submitState">{{ validationData.username.errors | oneError :
                                username.errors }} </div>
                        </div>
                    </div>
                    <div class="body-top-row-1-right-col-2" *ngIf="!data.editState || passwordChanged.value">
                        <div class="input-box-type">
                            <input type="password" autocomplete="no" formControlName="password"
                                [placeholder]="usermeta.formmsg.password.placeholder">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="password.touched || submitState">{{ validationData.password.errors | oneError :
                                password.errors }} </div>
                        </div>
                    </div>
                    <ng-container *ngIf="data.editState && !passwordChanged.value">
                        <div class="body-top-row-edit-pwd">
                            <div class="body-top-row-edit-left">
                                <div class="input-box-type-edit">
                                    <input formControlName="password" type="password"
                                        [placeholder]="usermeta.formmsg.password.placeholder">
                                </div>
                                <div class="alert-danger">
                                    <div *ngIf="password.touched || submitState">{{ validationData.password.errors |
                                        oneError : password.errors }} </div>
                                </div>
                            </div>
                            <div class="body-top-row-edit-right-1">
                                <img (click)="editPasswordClick()" src="assets/settings/edit-row.svg">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- <div class="body-top-row-2"> -->
                <!-- <label>
                    {{usermeta.formmsg.password.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-2-right" *ngIf="!data.editState || passwordChanged.value">
                   
                        <div class="input-box-type">
                            <input type="password" autocomplete="no" formControlName="password"
                                [placeholder]="usermeta.formmsg.password.placeholder">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="password.touched || submitState">{{ validationData.password.errors | oneError :
                                password.errors }} </div>
                        </div>
                    </div>
                    <div class="body-top-row-2-right-col-2">

                    </div>
                </div>
                <ng-container *ngIf="data.editState && !passwordChanged.value">
                    <div class="body-top-row-edit">
                        <div class="body-top-row-edit-left">
                            <div class="input-box-type-edit">
                                <input formControlName="password" type="password"
                                    [placeholder]="usermeta.formmsg.password.placeholder">
                            </div>
                            <div class="alert-danger">
                                <div *ngIf="password.touched || submitState">{{ validationData.password.errors |
                                    oneError : password.errors }} </div>
                            </div>
                        </div>
                        <div class="body-top-row-edit-right-1">
                            <img (click)="editPasswordClick()" src="assets/settings/edit-row.svg">
                        </div>
                    </div>
                </ng-container> -->
            <!-- </div> -->
            <div class="body-top-row-2">
                <label>
                    <!-- {{usermeta.formmsg.fullname.label}}<span class="text-danger">*</span> -->
                    Firstname<span class="text-danger">*</span>/Lastname
                </label>
                <div class="body-top-row-2-right">
                    <div class="body-top-row-2-right-col-1">
                        <div class="input-box-type">
                            <input formControlName="firstname" specialCharOmit [charList]="inputBoxEmitChar" [placeholder]="usermeta.formmsg.fullname.placeholder[0]">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="firstname.touched || submitState">{{ usermeta.formmsg.fullname.errors | oneError
                                : firstname.errors }} </div>
                        </div>
                    </div>
                    <div class="body-top-row-2-right-col-2">
                        <div class="input-box-type">
                            <input formControlName="lastname" type="text" specialCharOmit [charList]="inputBoxEmitChar" [placeholder]="usermeta.formmsg.fullname.placeholder[1]">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="lastname.touched || submitState">{{ usermeta.formmsg.fullname.errors |
                                combineError : lastname.errors : userDetail.errors }} </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-top-row-3">
                <label>
                    {{usermeta.formmsg.mobile.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-3-right" *ngIf="data.editState | pendingControl: pendingmobile?.value : 0">
                    <div class="input-box-type">
                        <input formControlName="mobile" specialCharOmit [charList]="numCharList" type="text" [placeholder]="usermeta.formmsg.mobile.placeholder">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="mobile.touched|| submitState">{{ validationData.mobile.errors | oneError :
                            mobile.errors }} </div>
                    </div>
                </div>
                <ng-container *ngIf="data.editState | pendingControl: pendingmobile?.value: 1">
                    <div class="body-top-row-edit">
                        <div class="body-top-row-edit-left">
                            <div class="input-box-type-edit">
                                
                                <input formControlName="mobile" type="text"
                                    [placeholder]="usermeta.formmsg.mobile.placeholder">
                            </div>
                            <div class="alert-danger">
                                <div *ngIf="mobile.touched || submitState">{{ validationData.mobile.errors | oneError
                                    : mobile.errors }} </div>
                            </div>
                        </div>
                        <div class="body-top-row-edit-right">
                            <p [title]="data.editdata.oldmobile">old</p>
                            <img (click)="mobileApproveClick(2)" src="assets/settings/tick.svg">
                            <img (click)="mobileApproveClick(-1)" src="assets/settings/reject.svg">
                        </div>
                    </div>
                </ng-container>

            </div>
            <div class="body-top-row-4">
                <label>
                    {{usermeta.formmsg.email.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-4-right" *ngIf="data.editState | pendingControl : pendingemail?.value : 0">
                    <div class="input-box-type">
                        <input specialCharOmit [charList]="emailchar" formControlName="email" [placeholder]="usermeta.formmsg.email.placeholder">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="email.touched || submitState">{{ validationData.email.errors | oneError :
                            email.errors }} </div>
                    </div>
                </div>
                <ng-container *ngIf="data.editState | pendingControl: pendingemail?.value: 1">
                    <div class="body-top-row-edit">
                        <div class="body-top-row-edit-left">
                            <div class="input-box-type-edit">
                                <input formControlName="email" [placeholder]="usermeta.formmsg.email.placeholder">
                                
                            </div>
                            <div class="alert-danger">
                                <div *ngIf="email.touched || submitState">{{ validationData.email.errors | oneError :
                                    email.errors }} </div>
                            </div>
                        </div>
                        <div class="body-top-row-edit-right">
                            <!-- <img src="assets/settings/warning.svg" [title]="data.editdata.oldemail"> -->
                            <p [title]="data.editdata.oldemail">old</p>
                            <img (click)="emailApproveClick(2)" src="assets/settings/tick.svg">
                            <img (click)="emailApproveClick(-1)" src="assets/settings/reject.svg">
                        </div>
                    </div>
                </ng-container>
            </div>
           
            <div class="body-top-row-5">
                <label>
                    {{usermeta.formmsg.role.label}}<span class="text-danger">*</span>/Default platform<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-5-right">
                    <div class="body-top-row-5-right-col-1">
                        <div class="input-box-type" #roleorigin>
                            <app-overlay-select [hasMultiSelect]=true formControlName="role" [data]="data.rolelist"
                                [origin]="roleorigin" [style]="{'font-size':'13px'}" [isSearch]="true"
                                [placeHolder]="usermeta.formmsg.role.placeholder">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="role.touched || submitState">{{ usermeta.formmsg.role.errors | oneError :
                                role.errors }} </div>
                        </div>
                    </div>
                    
                    <div class="body-top-row-5-right-col-2">
                        <div class="input-box-type" #typeorigin1>
                            <app-overlay-select  formControlName="default_platform"    
                              [data]="data.allplatforms" [origin]="typeorigin1"
                                [style]="{'font-size':'13px'}"
                                placeHolder="Select Platform">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="default_platform.touched || submitState">{{ usermeta.formmsg.default_platform.errors | oneError :
                                default_platform.errors }} </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="body-top-row-6">
                <label>
                    {{usermeta.formmsg.authtypeurl.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-6-right">
                    <div class="body-top-row-6-right-col-1">
                        <div class="input-box-type" #typeorigin>
                            <app-overlay-select formControlName="authenticationType"
                                [data]="usermeta.formmsg.authtypeurl.typefilterOptions" [origin]="typeorigin"
                                [style]="{'font-size':'13px'}"
                                [placeHolder]="usermeta.formmsg.authtypeurl.placeholder[0]">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger">
                            
                        </div>
                    </div>
                    <div class="body-top-row-6-right-col-2">
                        <div class="input-box-type" #urlorigin>
                            <app-overlay-select formControlName="authenticationUrl"
                                [data]="usermeta.formmsg.authtypeurl.urlfilterOptions" [origin]="urlorigin"
                                [style]="{'font-size':'13px'}"
                                [placeHolder]="usermeta.formmsg.authtypeurl.placeholder[1]">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="body-top-row-7">
                <label>
                    Timezone
                </label>
                <div class="body-top-row-6-right">
                    <div class="body-top-row-7-right-col-1">
                        <div class="input-box-type" #timezoneorigin>
                            <app-overlay-select [hasMultiSelect]=false formControlName="timezone" [data]="timezoneoptions"
                                [origin]="timezoneorigin" [style]="{'font-size':'13px'}"
                                [placeHolder]="usermeta.formmsg.timezone.placeholder">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="timezone.touched || submitState">{{ usermeta.formmsg.timezone.errors | oneError :
                                timezone.errors }} </div>
                        </div>
                    </div>
                    <div class="body-top-row-7-right-col-2">

                    </div>
                </div>
            </div> -->

            

        </div>

        <div class="body-bottom">
            <div class="body-bottom-row-1">
                <div class="body-bottom-row-1-col-1">
                    <label class="checkbox-container">
                        {{usermeta.formLabels[0]}}
                        <input type="checkbox" formControlName="active">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="body-bottom-row-1-col-2">
                    <label class="checkbox-container">
                        {{usermeta.formLabels[2]}}
                        <input type="checkbox"  formControlName="forcepwd">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="body-bottom-row-1-col-3">
                    <div class="input-box-type" #timezoneorigin>
                        <app-overlay-select [hasMultiSelect]=false formControlName="timezone" [data]="timezoneoptions"
                            [origin]="timezoneorigin" [style]="{'font-size':'13px'}"
                            [placeHolder]="usermeta.formmsg.timezone.placeholder">
                        </app-overlay-select>
                    </div>
                    <!-- <div class="alert-danger">
                        <div *ngIf="timezone.touched || submitState">{{ usermeta.formmsg.timezone.errors | oneError :
                            timezone.errors }} </div>
                    </div> -->
                </div>
            </div>
            <div class="body-bottom-row-2">
                <div class="body-bottom-row-2-left1">
                    <label class="checkbox-container">
                        {{usermeta.formLabels[1]}}
                        <input type="checkbox" formControlName="block">
                        <span class="checkmark"></span>
                    </label>
                    <div class="alert-danger">

                    </div>
                </div>
                <div class="body-bottom-row-2-left">
                    <div class="body-bottom-row-2-left-row-1">
                        <label>{{usermeta.formLabels[4]}}</label>
                        <input type="time"  formControlName="fromTime">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="block.value && (fromTime.touched || submitState)">{{ usermeta.formmsg.blockedtime.errors | oneError :
                            fromTime.errors }} </div>
                    </div>

                </div>

                <div class="body-bottom-row-2-right">
                    <div class="body-bottom-row-2-right-row-1">
                        <label>{{usermeta.formLabels[5]}}</label>
                        <input type="time"  formControlName="toTime">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="block.value && (toTime.touched || submitState)">{{ usermeta.formmsg.blockedtime.errors | oneError :
                            toTime.errors }} </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="footer">
        <div class="footer-buttons">
            <button class="cancel-button" (click)="closedialogClick()">{{this.usermeta.buttonTitle[2]}}</button>
            <button class="submit-button" (click)="submitUser()">{{data.editState ? this.usermeta.buttonTitle[1]
                :this.usermeta.buttonTitle[0]}}</button>
        </div>


    </div>
    
</div>




<!-- <div [formGroup]="userDetail" class="user-container">
    <div class="header">
        <h4>{{data.editState ? this.usermeta.headerTitle[1]:this.usermeta.headerTitle[0]}}</h4>
        <img (click)="closedialogClick()" src="assets/rule/close-dialog.svg">
    </div>
    <ng-container *ngIf="mat_spinner_user_state">
        <div class ="body-bottom-spinner">
            <mat-spinner class="spinner" diameter=20></mat-spinner>
        </div>
    </ng-container>
    <div class="body">
        <label class="highlight">{{usermeta.commonLabels[0]}}</label>
        <div class="body-top">
            <div class="body-top-row-1">
                <label>
                    {{usermeta.formmsg.username.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-1-right">
                    <div class="input-box-type">
                        <input formControlName="username" type="text" autocomplete="no" specialCharOmit [charList]="inputBoxEmitChar" [placeholder]="usermeta.formmsg.username.placeholder">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="username.touched || submitState">{{ validationData.username.errors | oneError :
                            username.errors }} </div>
                    </div>
                </div>
            </div>
            <div class="body-top-row-2">
                <label>
                    {{usermeta.formmsg.password.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-2-right" *ngIf="!data.editState || passwordChanged.value">
                    <div class="input-box-type">
                        <input type="password" autocomplete="no" formControlName="password"
                            [placeholder]="usermeta.formmsg.password.placeholder">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="password.touched || submitState">{{ validationData.password.errors | oneError :
                            password.errors }} </div>
                    </div>
                </div>
                <ng-container *ngIf="data.editState && !passwordChanged.value">
                    <div class="body-top-row-edit">
                        <div class="body-top-row-edit-left">
                            <div class="input-box-type-edit">
                                <input formControlName="password" type="password"
                                    [placeholder]="usermeta.formmsg.password.placeholder">
                            </div>
                            <div class="alert-danger">
                                <div *ngIf="password.touched || submitState">{{ validationData.password.errors |
                                    oneError : password.errors }} </div>
                            </div>
                        </div>
                        <div class="body-top-row-edit-right-1">
                            <img (click)="editPasswordClick()" src="assets/settings/edit-row.svg">
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="body-top-row-3">
                <label>
                    {{usermeta.formmsg.fullname.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-3-right">
                    <div class="body-top-row-3-right-col-1">
                        <div class="input-box-type">
                            <input formControlName="firstname" specialCharOmit [charList]="inputBoxEmitChar" [placeholder]="usermeta.formmsg.fullname.placeholder[0]">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="firstname.touched || submitState">{{ usermeta.formmsg.fullname.errors | oneError
                                : firstname.errors }} </div>
                        </div>
                    </div>
                    <div class="body-top-row-3-right-col-2">
                        <div class="input-box-type">
                            <input formControlName="lastname" type="text" specialCharOmit [charList]="inputBoxEmitChar" [placeholder]="usermeta.formmsg.fullname.placeholder[1]">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="lastname.touched || submitState">{{ usermeta.formmsg.fullname.errors |
                                combineError : lastname.errors : userDetail.errors }} </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-top-row-4">
                <label>
                    {{usermeta.formmsg.mobile.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-4-right" *ngIf="data.editState | pendingControl: pendingmobile?.value : 0">
                    <div class="input-box-type">
                        <input formControlName="mobile" specialCharOmit [charList]="numCharList" type="text" [placeholder]="usermeta.formmsg.mobile.placeholder">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="mobile.touched|| submitState">{{ validationData.mobile.errors | oneError :
                            mobile.errors }} </div>
                    </div>
                </div>
                <ng-container *ngIf="data.editState | pendingControl: pendingmobile?.value: 1">
                    <div class="body-top-row-edit">
                        <div class="body-top-row-edit-left">
                            <div class="input-box-type-edit">
                                <p>{{data.editdata.oldmobile}}</p>
                                <input formControlName="mobile" type="text"
                                    [placeholder]="usermeta.formmsg.mobile.placeholder">
                            </div>
                            <div class="alert-danger">
                                <div *ngIf="mobile.touched || submitState">{{ validationData.mobile.errors | oneError
                                    : mobile.errors }} </div>
                            </div>
                        </div>
                        <div class="body-top-row-edit-right">
                            <img (click)="mobileApproveClick(2)" src="assets/settings/tick.svg">
                            <img (click)="mobileApproveClick(-1)" src="assets/settings/reject.svg">
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="body-top-row-5">
                <label>
                    {{usermeta.formmsg.email.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-5-right" *ngIf="data.editState | pendingControl : pendingemail?.value : 0">
                    <div class="input-box-type">
                        <input specialCharOmit [charList]="emailchar" formControlName="email" [placeholder]="usermeta.formmsg.email.placeholder">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="email.touched || submitState">{{ validationData.email.errors | oneError :
                            email.errors }} </div>
                    </div>
                </div>
                <ng-container *ngIf="data.editState | pendingControl: pendingemail?.value: 1">
                    <div class="body-top-row-edit">
                        <div class="body-top-row-edit-left">
                            <div class="input-box-type-edit">
                                <p>{{data.editdata.oldemail | limitString : 50}}</p>
                                <input formControlName="email" [placeholder]="usermeta.formmsg.email.placeholder">
                            </div>
                            <div class="alert-danger">
                                <div *ngIf="email.touched || submitState">{{ validationData.email.errors | oneError :
                                    email.errors }} </div>
                            </div>
                        </div>
                        <div class="body-top-row-edit-right">
                            <img (click)="emailApproveClick(2)" src="assets/settings/tick.svg">
                            <img (click)="emailApproveClick(-1)" src="assets/settings/reject.svg">
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="body-top-row-6">
                <label>
                    {{usermeta.formmsg.role.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-6-right">
                    <div class="body-top-row-6-right-col-1">
                        <div class="input-box-type" #roleorigin>
                            <app-overlay-select [hasMultiSelect]=true formControlName="role" [data]="data.rolelist"
                                [origin]="roleorigin" [style]="{'font-size':'13px'}" [isSearch]="true"
                                [placeHolder]="usermeta.formmsg.role.placeholder">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="role.touched || submitState">{{ usermeta.formmsg.role.errors | oneError :
                                role.errors }} </div>
                        </div>
                    </div>
                    
                    <div class="body-top-row-6-right-col-2">
                        <div class="input-box-type" #timezoneorigin>
                            <app-overlay-select [hasMultiSelect]=false formControlName="timezone" [data]="timezoneoptions"
                                [origin]="timezoneorigin" [style]="{'font-size':'13px'}"
                                [placeHolder]="usermeta.formmsg.timezone.placeholder">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="timezone.touched || submitState">{{ usermeta.formmsg.timezone.errors | oneError :
                                timezone.errors }} </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-top-row-7">
                <label>
                    {{usermeta.formmsg.authtypeurl.label}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-7-right">
                    <div class="body-top-row-7-right-col-1">
                        <div class="input-box-type" #typeorigin>
                            <app-overlay-select formControlName="authenticationType"
                                [data]="usermeta.formmsg.authtypeurl.typefilterOptions" [origin]="typeorigin"
                                [style]="{'font-size':'13px'}"
                                [placeHolder]="usermeta.formmsg.authtypeurl.placeholder[0]">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger">
                            
                        </div>
                    </div>
                    <div class="body-top-row-7-right-col-2">
                        <div class="input-box-type" #urlorigin>
                            <app-overlay-select formControlName="authenticationUrl"
                                [data]="usermeta.formmsg.authtypeurl.urlfilterOptions" [origin]="urlorigin"
                                [style]="{'font-size':'13px'}"
                                [placeHolder]="usermeta.formmsg.authtypeurl.placeholder[1]">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger">
                        </div>
                    </div>
                </div>
            </div>

            <div class="body-top-row-8">
                <label>
                    Platform<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-8-right">
                    <div class="body-top-row-8-right-col-1">
                        <div class="input-box-type" #typeorigin1>
                            <app-overlay-select  formControlName="platform"    
                              [data]="data.allplatforms" [origin]="typeorigin1"
                                [style]="{'font-size':'13px'}"
                                placeHolder="default platform">
                            </app-overlay-select>
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="platform.touched || submitState">{{ usermeta.formmsg.platform.errors | oneError :
                                platform.errors }} </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="body-bottom">
            <div class="body-bottom-row-1">
                <div class="body-bottom-row-1-col-1">
                    <label class="checkbox-container">
                        {{usermeta.formLabels[0]}}
                        <input type="checkbox" formControlName="active">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="body-bottom-row-1-col-2">
                    <label class="checkbox-container">
                        {{usermeta.formLabels[2]}}
                        <input type="checkbox"  formControlName="forcepwd">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            <div class="body-bottom-row-2">
                <div class="body-bottom-row-2-left1">
                    <label class="checkbox-container">
                        {{usermeta.formLabels[1]}}
                        <input type="checkbox" formControlName="block">
                        <span class="checkmark"></span>
                    </label>
                    <div class="alert-danger">

                    </div>
                </div>
                <div class="body-bottom-row-2-left">
                    <div class="body-bottom-row-2-left-row-1">
                        <label>{{usermeta.formLabels[4]}}</label>
                        <input type="time"  formControlName="fromTime">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="block.value && (fromTime.touched || submitState)">{{ usermeta.formmsg.blockedtime.errors | oneError :
                            fromTime.errors }} </div>
                    </div>

                </div>

                <div class="body-bottom-row-2-right">
                    <div class="body-bottom-row-2-right-row-1">
                        <label>{{usermeta.formLabels[5]}}</label>
                        <input type="time"  formControlName="toTime">
                    </div>
                    <div class="alert-danger">
                        <div *ngIf="block.value && (toTime.touched || submitState)">{{ usermeta.formmsg.blockedtime.errors | oneError :
                            toTime.errors }} </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="footer">
        <div class="footer-buttons">
            <button class="cancel-button" (click)="closedialogClick()">{{this.usermeta.buttonTitle[2]}}</button>
            <button class="submit-button" (click)="submitUser()">{{data.editState ? this.usermeta.buttonTitle[1]
                :this.usermeta.buttonTitle[0]}}</button>
        </div>


    </div>
    
</div> -->