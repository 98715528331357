<!-- <div *ngIf="widgetName !=null" class="dashboard-widget-container" #refContainer [id]='widgetName+"htmlData"'> -->
<div *ngIf="widgetName" class="dashboard-widget-container" [id]='widgetName+"htmlData"'>

    <div class="header-wrapper">
        <!-- widget details header-->
        <!-- <ng-container *ngIf="widgetDetail != null && widgetDetail !=undefined"> -->
        <ng-container *ngIf="widgetDetail  && !errorhandledashboardwidget.status">
            <div class="header-col-1" (click)="closeOptions_click()">
                <h4>{{widgetDetail.title}}</h4>
                <p>{{CustomFilterSub}}</p>
            </div>
            <!-- more option button show/hide   !moreOptionState &&-->
            <div class="header-col-2" *ngIf="moreOptionEnable">
                <button #moreOptionOrigin>
                    <!-- <img  *ngIf="!moreOptionState" src="assets/widgets/more-options.svg" (click)="moreOptions_click()"
                        (click)="$event.stopPropagation()"> -->
                    <img src="assets/widgets/more-options.svg" [matTooltip]="moreoptiontitles[0]"
                        (click)="openOverlay_moreoptions_click(moreOptionRef,moreOptionOrigin)">

                </button>
            </div>
            <!-- more options - refresh,download pdf, date filter, full screen and color palatte -->
            <div class="header-col-2-1" (click)="$event.stopPropagation()">
                <!-- more oprions in template - this is loaded when more oprions is clicked -->
                <ng-template #moreOptionRef>
                    <!-- *ngIf="moreOptionState" (click)="$event.stopPropagation()" -->
                    <div class="more-option">
                        <div class="more-option-container">
                            <div class="icon-div"
                                (click)="load_widgetData('refresh',loginService.metaData.dashboard.dateFilter_default.default_fromDt,'refresh')">
                                <img src="assets/widgets/refresh.svg" class="enable-action"
                                    [matTooltip]="moreoptiontitles[1]">
                                <div class="vertical-line"></div>
                            </div>
                            <div class="icon-div" (click)="download_click()">
                                <img src="assets/widgets/download.svg"
                                    [ngClass]="handledashboardwidgetnodata.status ? 'disable-action':'enable-action'"
                                    [matTooltip]="moreoptiontitles[2]">
                                <div class="vertical-line"></div>
                            </div>
                            <div class="icon-div" (click)="openOverlay_click(dateFilterRef,filterOrigin,'datefilter')">
                                <img src="assets/widgets/filter.svg" #filterOrigin [matTooltip]="moreoptiontitles[3]"
                                    class="enable-action">
                                <div class="vertical-line"></div>
                            </div>
                            <div class="icon-div" (click)="fullscreen_click(headerContent,content,subtitle)">
                                <!-- #fullscreenOrigin -->
                                <img src="assets/widgets/full-screen.svg"
                                    [ngClass]="handledashboardwidgetnodata.status ? 'disable-action':'enable-action'"
                                    [matTooltip]="moreoptiontitles[4]">
                                <!-- ,fullscreenOrigin -->
                                <!-- <ng-container *ngIf="widgetType=='Chart'"> -->
                                <div class="vertical-line"></div>
                            </div>
                            <div class="icon-div" #colorPalateOrigin
                                (click)="openOverlay_click(colorPalateRef,colorPalateOrigin,'colorpalate')">
                                <img *ngIf="widgetType=='Chart'" src="assets/widgets/edit-color.svg"
                                    [matTooltip]="moreoptiontitles[5]"
                                    [ngClass]="handledashboardwidgetnodata.status ? 'disable-action':'enable-action'">
                                <!-- </ng-container> -->
                                <!-- color palatte option is disabled for Table -->
                                <img *ngIf="widgetType=='Table'" style="cursor:default;opacity: 32%;"
                                    src="assets/widgets/edit-color-disable.svg">
                            </div>

                        </div>
                        <div class="arrow-div">
                            <!-- <div class="arrow">

                            </div> -->
                            <img src="assets/widgets/play-gray.svg">
                        </div>
                        <div class="close-option">
                            <button class="icon-button" (click)="closeOptions_click()"
                                [matTooltip]="moreoptiontitles[6]">x
                                <!-- <img class="exit-option" src="assets/widgets/close-more-options.svg" class="enable-action"
                            (click)="closeOptions_click()"> -->
                                <!-- <img class="exit-option" src="assets/widgets/window-close-line.svg"
                                    class="enable-action" (click)="closeOptions_click()"> -->
                            </button>
                        </div>
                    </div>
                </ng-template>
                <!-- date filter options in a template- this is loaded when date filter option is clicked -->
                <ng-template #dateFilterRef>
                    <div class="more-option-filter-container">
                        <div class="more-option-filter">
                            <div class="filter-header-wrapper">
                                <ul id="dateFilter">
                                    <ng-container
                                        *ngFor="let filter of this.loginService.metaData.dashboard.dateFilter|keyvalue : originalOrder; let i = index">
                                        <li (click)="load_widgetData('dateFilter',filter.value,'relativetime')">
                                            {{ filter.key }} </li>
                                    </ng-container>
                                </ul>
                                <hr class="horizondal-seperator">
                            </div>
                            <div class="filter-body-wrapper" [formGroup]="selectTime">
                                <form>
                                    <div class="filter-body-row-1">
                                        <p>From</p>
                                        <!-- <input type="date" id="start" [value]="fromDate | date : 'yyyy-MM-dd'"
                                            (input)="fromDate = $event.target.value"
                                            (change)="load_widgetData('dateFilter')"> -->

                                        <input type="datetime-local" id="to" formControlName="fromDate" [min]="mindate" [max]="maxdate"
                                            matTooltip="{{selectTime.controls.toDate.value | date:'dd MMM yy HH:mm'}}">

                                    </div>
                                    <div class="filter-body-row-2">
                                        <p>To</p>
                                        <!-- <input type="date" id="end" [value]="toDate | date : 'yyyy-MM-dd'"
                                            (input)="toDate = $event.target.value"
                                            (change)="load_widgetData('dateFilter')"> -->
                                        <input type="datetime-local" id="from" formControlName="toDate" [min]="mindate" [max]="maxdate"
                                            matTooltip="{{selectTime.controls.toDate.value | date:'dd MMM yy HH:mm'}}">
                                        <button class="apply"
                                            (click)="load_widgetData('dateFilter','','customtime')">Apply</button>
                                    </div>
                                    <span class="date-validation" *ngIf="selectTime.errors">
                                        {{loginService.metaData.rule.lookbackmeta.formerrormsg.invalidtime}} </span>
                                </form>

                            </div>
                        </div>
                    </div>
                </ng-template>
                <!-- color palatte in template - this is loaded when color palatte option is clicked -->
                <ng-template #colorPalateRef>
                    <div class="edit_color_palet">
                        <div class="color-container">
                            <!-- #selectedColor -->
                            <ng-container *ngFor="let colors of loginService.metaData.dashboard.colorPalette ; let i=index">
                                <div >
                                    <div class="color-1" [ngClass]="i==selectedColorIndex?'color-planel-highlight':''"
                                        (click)="colorPalatte_click(colors,i)">
                                        <!--  id="selectColor{{i}}" -->
                                        <ng-container *ngFor="let color of colors">
                                            <!-- <input type=color [value]="color" (click)="$e  vent.stopImmediatePropagation()"> -->
                                            <span class="pix" [ngStyle]="{'background-color': color}"></span>
                                            <!-- (colorEvent)="valuePasstochild($event)" -->
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-container>
        <ng-container *ngIf="errorhandledashboardwidget.status">
            <div></div>
            <div class="dashboard-refresh">
                <img src="assets/widgets/refresh.svg" class="enable-action" [matTooltip]="moreoptiontitles[1]"
                    (click)="load_widgetData('refresh',loginService.metaData.dashboard.dateFilter_default.default_fromDt,'refresh')">
            </div>
        </ng-container>

    </div>
    <!-- body - table or chart is loaded , click on body will close the opened more options panel -->
    <div class="body-wrapper" (click)="closeOptions_click()">
        <!-- <ng-container *ngIf="widgetDetail != null"> -->
        <ng-container *ngIf="widgetDetail && !handledashboardwidgetnodata.status && !errorhandledashboardwidget.status">
            <!-- table widget -->
            <app-display-table *ngIf="widgetType=='Table'" class="table" [headers]="widgetDetail.tableHeaders"
                [rows]="widgetDetail.tableRows">
            </app-display-table>
            <!-- chart widget -->
            <div *ngIf="widgetType=='Chart'" echarts [id]="widgetDetail.widgetName" [options]="chartOption"
                class="chart"></div>
        </ng-container>
        <!-- mat spinner is loaded until widget details loaded from api -->
        <!-- <ng-container *ngIf="widgetDetail == null && widgetName !=null"> -->
        <ng-container *ngIf="spinnerState">
            <mat-spinner class="spinner" diameter=20></mat-spinner>
            <!-- <img [src]="widgetImg" alt=""> -->
        </ng-container>
        <ng-container *ngIf="handledashboardwidgetnodata.status || errorhandledashboardwidget.status">
            <div class="dashboardwidget-nodata">
                <div class="nodata-img">
                    <img src="assets/widgets/empty-data.svg">
                </div>
                <div>
                    <p>{{handledashboardwidgetnodata.status ?
                        handledashboardwidgetnodata.errmsg:errorhandledashboardwidget.errmsg}}</p>
                </div>
            </div>
        </ng-container>
    </div>

</div>
<!-- template to load full screen -->
<ng-template #headerContent>{{widgetDetail.title}}</ng-template>
<!-- <ng-template #subtitle>{{widgetDetail.subTitle}}</ng-template> -->
<ng-template #subtitle>{{CustomFilterSub}}</ng-template>

<ng-template #content>
    <div class="body-wrapper">
        <app-display-table *ngIf="widgetType=='Table'" class="table" [headers]="widgetDetail.tableHeaders"
            [rows]="widgetDetail.tableRows">
        </app-display-table>
        <div *ngIf="widgetType=='Chart'" echarts [id]="widgetDetail.widgetName + 'Template'" [options]="chartOption"
            class="chart">
        </div>
    </div>
</ng-template>