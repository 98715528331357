import { AbstractControl, ValidatorFn } from "@angular/forms";

export function VerifyUserName():ValidatorFn {
    
    return (control:AbstractControl): {[key:string]:any} | null => {

        // const regex = new RegExp("^[A-Za-z]")
        const regex = new RegExp("^[a-zA-Z0-9]+@[a-zA-Z0-9]+(\.[a-zA-Z]{2,})?$");
        if(control.value){
            if(!regex.test(control.value)){
                return {invalidName:true}
            }
        }
        return null
    }   
}