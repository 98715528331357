<div class="add-role-container" [formGroup]="addRoleForm">
    <div class="header">
        <h4>{{data.header}}</h4>
        <label for="">Service provider:<span>{{service_provider_name}}</span></label>
        <img (click)="close()" class="close-img" src="assets/close-dialog.svg" alt="close">
    </div>
    <ng-container *ngIf="mat_spinner_role_state">
        <div class="body-bottom-spinner">
            <mat-spinner class="spinner" diameter=20></mat-spinner>
        </div>
    </ng-container>
    <div class="body-wrapper">
        <div class="mandatory-field">* Mandatory</div>
        <div class="body-row-1">
            <div class="body-row-1-col-1">
                <label for="role_name">
                    {{roleMetaData.labels[0]}}<span class="text-danger">*</span>
                </label>
                <div class="body-top-row-1-right">
                    <div class="body-top-row-1-right-col-1">
                        <div class="input-box-type">
                            <input  type="text" autocomplete="no" [placeholder]="roleMetaData.placeholder[0]" formControlName="role_name">
                        </div>
                        <div class="alert-danger">
                            <div *ngIf="(role_name.touched || submitState)" >
                                {{roleMetaData.error.role_name|oneError: role_name.errors}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-row-1-col-2">
                
                <label for="assigned users">
                    {{roleMetaData.labels[1]}}
                </label>
                <div class="body-row-2-col-1-col-2" #userorigin>
                    <app-overlay-select [hasMultiSelect]=true [data]="data.userlist" formControlName="assigned_user"
                        [origin]="userorigin" [style]="{'font-size':'13px'}" [placeHolder]="roleMetaData.placeholder[1]" [isSearch]="true">
                    </app-overlay-select>
                    <div class="alert-danger">
                        <div></div>
           
                    </div> 
                </div>   
            </div>
        </div>

        <div class="body-row-2">
            <div class="body-row-2-col-1">
                <label for="role_desc">
                    {{roleMetaData.labels[2]}}<span class="text-danger">*</span>
                </label>
                    <div class="body-row-2-col-1-col-1">
                        <div class="body-row-2-col-1-col-1-col-1">
                            <div class="input-box-type">
                                <input  type="text" autocomplete="no" [placeholder]="roleMetaData.placeholder[2]" formControlName="role_desc">
                            </div>
                            <div class="alert-danger">
                                <div *ngIf="(role_desc.touched || submitState)" >
                                    {{roleMetaData.error.role_desc|oneError: role_desc.errors}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
         
        </div>
        <div class="body-row-3">
            <div class="body-row-3-col-1">
                <div class="customer-header">
                    <span>Customers</span>
                </div>
                <div class="body-row-3-col-1-row-2">
                    
                    <ng-container *ngFor="let customerInfo of  customerwise_role_infos |keyvalue let i=index" >
                        <div class="choose-customer" [ngClass]="(selectedCustomer==customerInfo.key)?'selected-customer':''" id="customer-{{i}}" (click)="changeCutsomerClick(i,customerInfo,$event)">
                            <span [ngClass]="customerInfo.value.isselected?'already-selected-customer':'notseleted-coustomer'">{{customerInfo.value.disp}}</span>
                        </div>

                    </ng-container>

                </div>
            </div>
            <div class="body-row-3-col-2">
                <tabs>
                    <tab label="Platform">
                        <label>
                            Choose platform<span class="text-danger">*</span>
                        </label>
                        <div class="platform-wrapper" formGroupName="platforms">
                           
                            <ng-container *ngFor="let platform of  platforms?.controls |keyvalue:originalOrder">
                                <div class="platform-panel">
                                    <div>

                                        <label class="checkbox-container" >
                                            <input type="checkbox"  [formControlName]="platform.key" >
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <label for="platforn">{{selectedCustomerData.platforms[platform.key].disp}}</label>

                                </div>
                            </ng-container>
                        </div>


                    </tab>
                    <tab label="Menu">
                        <label>
                            Choose menu<span class="text-danger">*</span>
                        </label>
                        <div class="menu-wrapper" formGroupName="menulist">
                            <ng-container *ngFor="let menulist of  menulist?.controls |keyvalue:originalOrder">
                                <div class="category-panel" [formGroupName]="menulist.key">
                                   
                                        <label for="category" class="category-label">{{selectedCustomerData.menulist[menulist.key].category}}</label>
                                  
                                    <div class="category">
                                        <div></div>
                                        <div class="menu-category">
                                            <ng-container *ngFor="let categroycontrol of  getMenucategoryControl(menulist.key)?.controls |keyvalue:originalOrder">
                                                <ng-container [formGroupName]="categroycontrol.key">
                                                            <div class="menu-panel">
                                                                <ng-container *ngFor="let menucontrol of  getMenuControl(menulist.key,categroycontrol.key)?.controls |keyvalue:originalOrder">
                                                                    <ng-container *ngIf="menucontrol.key != 'actions'" >
                                                                        <div>
                                                                            <label class="checkbox-container" >
                                                                                <input type="checkbox" [formControlName] ="menucontrol.key" (click)="menuActionToggleControl(menulist.key,categroycontrol.key)">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                        </div>
                                                                        <label for="menu">{{selectedCustomerData.menulist[menulist.key]['menu'][menucontrol.key].disp}}</label> 
                                                                    </ng-container>
                                                                    <ng-container *ngIf="menucontrol.key == 'actions'"  formGroupName="actions">
                                                                        <ng-container *ngFor="let action of  getActionControl(menulist.key,categroycontrol.key)?.controls |keyvalue:originalOrder">
                                                                            <div >
                                                                                <label class="checkbox-container" >
                                                                                    <input type="checkbox" [formControlName]="action.key" (click)="actionToggleControl(action.key,menulist.key,categroycontrol.key)">
                                                                                    <span class="checkmark-lite"></span>
                                                                                </label>
                                                                            </div>
                                                                           
                                                                            <label for="action" class="menu-label" >{{getActionDisp(menulist.key,categroycontrol.key,action.key)}}</label>
                                                                        </ng-container>
                                                                    </ng-container> 
                                                                </ng-container>
                                                               
                                                            </div>
                                                           
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        
                    </tab>

                </tabs>
            </div>


            


            <div class="alert-danger">
                <div *ngIf="platforms.errors?.customError" >
                    {{roleMetaData.error.platforms.required}}
                </div>
            </div>
        </div>
        

    </div>
    <div class="footer">
        <button class="cancel-button" (click)="cancel()">{{data.buttonLabels[1]}}</button>
        <button class="submit-button" (click)="submitRole_click()">{{data.buttonLabels[0]}}</button>
    </div>
</div>






<!-- <div class="add-role-container" [formGroup]="addRoleForm">
    <div class="header">
        <h4>{{data.header}}</h4>
        <img (click)="close()" class="close-img" src="assets/close-dialog.svg" alt="close">
    </div>
    <ng-container *ngIf="mat_spinner_role_state">
        <div class="body-bottom-spinner">
            <mat-spinner class="spinner" diameter=20></mat-spinner>
        </div>
    </ng-container>
    <div class="body-wrapper">
        <div class="mandatory-field">* Mandatory</div>
        <div class="body-row-1">
            <div class="body-row-1-col-1">
                <div class="input-label">
                    <label>
                        {{roleMetaData.labels[0]}}<span class="text-danger">*</span>
                    </label>
                </div>
                <div class="body-row-1-col-1-col-2">
                    <div class="input-box-type">
                        <input type="text" specialCharOmit [charList]="inputBoxEmitChar" placeholder="Enter Role Name"
                            formControlName="role_name">
                    </div>
                    <div class="alert-danger">
                        <ng-container *ngIf="roleName.touched || showErrors">
                            {{roleMetaData.roleName|oneError: roleName.errors}}
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="body-row-1-col-2">
                <div class="input-label">
                    <label>
                        {{roleMetaData.labels[1]}}
                    </label>
                </div>
                <div class="body-row-1-col-2-col-2">
                    <div class="input-box-type">
                        <input type="text" disabled formControlName="product">
                    </div>
                </div>

            </div>
        </div>
        <div class="body-row-2">
            <div class="body-row-2-col-1">
                <div class="body-row-2-col-1-col-1">
                    <label>{{roleMetaData.labels[2]}}<span class="text-danger">*</span></label>
                </div>
                <div class="body-row-2-col-1-col-2" #roleorigin>
                    <app-overlay-select [hasMultiSelect]=true [data]="sourceList" formControlName="source"
                        [origin]="roleorigin" [style]="{'font-size':'13px'}" placeHolder="Source" [isSearch]="true">
                    </app-overlay-select>
                    <div class="alert-danger">
                        <ng-container *ngIf="(source.touched || showErrors) && source.errors">
                            {{roleMetaData.source.sourcelength}}
                        </ng-container>

                    </div>
                </div>
            </div>
            <div class="body-row-2-col-2">
                <div class="body-row-2-col-2-col-1">
                    <label>{{roleMetaData.labels[3]}}</label>
                </div>
                <div class="body-row-2-col-2-col-2" #userorigin>
                    <app-overlay-select [hasMultiSelect]=true [data]="data.userlist" formControlName="assigned_user"
                        [origin]="userorigin" [style]="{'font-size':'13px'}" placeHolder="user List" [isSearch]="true">
                    </app-overlay-select>
                    <div class="alert-danger">
                        <ng-container *ngIf="users.touched||showErrors">
                            {{roleMetaData.assignedUsers|oneError: users.errors}}
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="body-row-3">
            <div class="body-row-3-col-1-row-1">
                <label>{{roleMetaData.labels[4]}}<span class="text-danger">*</span></label>
            </div>
            <div class="body-row-3-col-1-row-2" formGroupName="menu_list">
                <ng-container *ngFor="let category of data.menulist; index as i">
                    <div class="category-name">
                        <h4>{{label[i]}}</h4>
                    </div>
                    <ng-container *ngFor="let menu of category.menu">
                        <div class="checkbox-custom">
                            <label class="checkbox-container">{{menu.menu_name}}
                                <input type="checkbox" (click)="$event.stopPropagation()"
                                    [formControlName]="menu.menu_id">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </ng-container>
                </ng-container>

            </div>
            <div class="menu-error-message" *ngIf="menu_list.touched||showErrors">
                {{roleMetaData.choosenMenu|oneError:menu_list.errors}}
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="cancel-button" (click)="cancel()">{{data.buttonLabels[1]}}</button>
        <button class="submit-button" (click)="submitRole_click()">{{data.buttonLabels[0]}}</button>
    </div>
</div> -->