import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmedValidator } from 'src/app/shared/validators/matchPassword.validator';
import { VerifyPasswordCombination } from 'src/app/shared/validators/passwordStrength.validator';
import { PrePasswordValidator } from 'src/app/shared/validators/prepwdAsync.validator';
import { UserLoginService } from '../user-login.service';
import { ToastService } from 'src/app/shared/custom-overlay/toast/toast.service';


@Component({
  selector: 'app-new-pwd',
  templateUrl: './new-pwd.component.html',
  styleUrls: ['./new-pwd.component.scss']
})
export class NewPwdComponent implements OnInit,OnDestroy {
  metaData
  strongPassword:boolean
  showPass1:boolean
  showPass2:boolean
  pwd:UntypedFormGroup
  errorMessage:boolean=false
  commonData
  $destroy:Subject<boolean>=new Subject()
  validationData = this.userService.metaData.commonData.userDetailvalidationData

  resetPasswordSpinner:boolean = false;

  constructor(
    private fb:UntypedFormBuilder,
    private ref:MatDialogRef<NewPwdComponent>,
    private userService:UserLoginService,
    private toastservice: ToastService,
    ) { {ref.disableClose = true;}}

  headertitle='Create New Password'

  inputPlaceholders=['*******', 'Enter password']

  ngOnInit(): void {
    console.log("new-pwd",this.validationData)
    this.metaData = this.userService.metaData.userLogin.newPwd
    this.strongPassword = this.userService.metaData.userLogin.newPwd.pwdStrength.strength;
    this.commonData = this.userService.metaData.commonData

    this.pwd = this.fb.group({
      newpassword:['',[
        Validators.required,
        VerifyPasswordCombination(this.strongPassword),
        Validators.minLength(this.validationData.password.minlength),
        Validators.maxLength(this.validationData.password.maxlength)],
        [PrePasswordValidator.createValidator(this.userService)]
      ],
      reenterpassword:['',[
        Validators.required,
        Validators.required,
        Validators.minLength(this.validationData.password.minlength),
        Validators.maxLength(this.validationData.password.maxlength)]]
    },{
      validators:ConfirmedValidator('newpassword','reenterpassword')
    })
      
  }


  get newPwd(){
    return this.pwd.get('newpassword')
  }

  get reEnterPwd(){
    return this.pwd.get('reenterpassword')
  }

  get pwdForm(){
    return this.pwd.controls
  }

  close_click(){
    this.ref.close()
  }
  submit_click(){
     if(this.pwd.valid){
      this.resetPasswordSpinner = true;
       this.errorMessage=false
        this.userService.postNewpwd({"user_name":this.userService.userName,"pwd":this.newPwd.value})
        .pipe(takeUntil(this.$destroy))
        .subscribe(res=>{
          this.resetPasswordSpinner = false;
          this.newPwd.reset();
          this.ref.close("submit");

          if(res['status']){
            this.toastservice.show({
              type:"success",
              text:res['status']
            })
          } else {
            this.toastservice.show({
              type:"failure",
              text:"Reset password failed"
            })
          }

        },
        (err)=>{
          this.resetPasswordSpinner = false;
        })
    }
    else{
      this.errorMessage=true;
    } 
  }

  cancel_click(){
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
