import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ToastService } from '../shared/custom-overlay/toast/toast.service';
import { ConfirmedValidator } from '../shared/validators/matchPassword.validator';
import { VerifyPasswordCombination } from '../shared/validators/passwordStrength.validator';
import { PrePasswordValidator } from '../shared/validators/prepwdAsync.validator';
import { UserLoginService } from './user-login.service';

// from metadata errors object is sent to oneError pipe with the error
@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('UName') UNameRef: ElementRef;
  @ViewChild('pwd') pwdRef: ElementRef;
  @ViewChild('signIn') signInRef: ElementRef;


  display = "";
  timeControler;
  timerState: boolean = false;
  errorMessage;
  qtnList;
  forgotPwdmetaData;
  newPwdmetaData;
  strongPassword: boolean;
  forgotPwdState: number = 0;
  showPass1: boolean;
  showPass2: boolean;
  showForgotError: boolean = false;
  showNewPassError: boolean = false;
  $destroy: Subject<boolean> = new Subject();
  mat_spinner_forgotpwd_state: boolean;
  validationData: any = { "password": { "minlength": 8, "maxlength": 30 }, "secret_ques": { "minlength": 3, "maxlength": 50 } };


  //form contols for loginForm
  loginForm = this.fb.group({
    userName: ["", [Validators.required]],
    pwd: ["", [Validators.required]],
  })

  //form contols for pwdVerifyQuestion
  pwdVerify = this.fb.group({
    user_name: [],
    ans1: ['', [Validators.required, Validators.maxLength(this.validationData.secret_ques.maxlength), Validators.minLength(this.validationData.minlength)]],
    ans2: ['', [Validators.required, Validators.maxLength(this.validationData.secret_ques.maxlength), Validators.minLength(this.validationData.minlength)]],
    verificationCode: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(4)]]

  })
  setPwd: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    public _service: UserLoginService,
    private access: AuthService,
    public matdialog: MatDialog,
    private toastservice: ToastService,

  ) { }

  ngAfterViewInit(): void {
    this.UNameRef.nativeElement.focus();
  }

  ngOnInit(): void {
    this.forgotPwdState = 0;
    this.setPwd = this.fb.group({
      user_name: [],
      newPwd: [null, [
        Validators.required,
        VerifyPasswordCombination(this.strongPassword),
        Validators.minLength(this.validationData.password.minlength),
        Validators.maxLength(this.validationData.password.maxlength)],
        [PrePasswordValidator.createValidator(this._service)]
      ],
      reEnterPwd: [null, [
        Validators.required,
        Validators.required,
        Validators.minLength(this.validationData.password.minlength),
        Validators.maxLength(this.validationData.password.maxlength)]]
    }, {
      validators: ConfirmedValidator('newPwd', 'reEnterPwd')
    })
    this._service.customerChanged_.subscribe((customer) => {
      this.changeCustomer(customer)
      this._service.serviceProviderName = customer.serviceProvider
    })

  }

  //dashboard module have to be loaded AFTER this._service.getMetaData(),
  //so async - await is used 
  login() {
    if (!this.userName) {
      this.errorMessage = "Please enter user name";  //this.forgotPwdmetaData.genErrors.uname //
      this.userName.focus;
    } else if (!this.pwd) {
      this.errorMessage = "Please enter password";  //this.forgotPwdmetaData.genErrors.uname //
      this.pwd.focus;
    }
    else {
      this._service.mat_spinner_login_state = true;
      this._service.userName = this.userName;
      this._service.getUser(this.userName, this.pwd)
        .then(val => {
          this.errorMessage = '';
          this._service.getMetaData({ "user_name": this.userName })
            .then(async metadata => {
              this.validationData = this._service.metaData.commonData.userDetailvalidationData
              this.access.requestAccessToken()
              this._service.getTimezone({ "user_name": this.userName }).toPromise()
                .then((timezone) => {
                  this._service.timezone = timezone['timezone'];
                  this._service.mat_spinner_login_state = false;
                  this.router.navigateByUrl('home');
                  // ----------------------//
                })
                .catch(err => {
                  this._service.mat_spinner_login_state = false;
                })

            })
            .catch(err => {
              this._service.mat_spinner_login_state = false;
            })
        })
        .catch((err) => {
          if (err) this.errorMessage = err;
          this._service.mat_spinner_login_state = false;
        })
    }
  }
  onKeyDown_UName(event) {
    this.clearErrormsg();
    switch (event.key) {
      case "ArrowDown":
      case "Enter":
        this.pwdRef.nativeElement.focus();
    }
  }
  onKeyDown_pwd(event) {
    this.clearErrormsg();
    switch (event.key) {
      case "ArrowDown":
      case "Enter":
        this.signInRef.nativeElement.focus();
    }
  }

  clearErrormsg() {
    this.errorMessage = null
  }

  get userName() { return this.loginForm.get('userName').value; }

  get pwd() { return this.loginForm.get('pwd').value; }

  forgotPassword_click() {
    this.errorMessage = null
    if (this.userName) {
      this.mat_spinner_forgotpwd_state = true;
      this._service.userName = this.userName;
      // api to get metadata and question list
      this._service.getUserSecretQuestions({ "user_name": this.userName })
        .pipe(takeUntil(this.$destroy))
        .subscribe((val: any) => {
          if (val.error) {
            this.errorMessage = val.errmsg
          }
          else {
            this.validationData = val.validation_data
            this.forgotPwdmetaData = val.meta_data
            this.qtnList = val.qtnList;
            this.newPwdmetaData = this.forgotPwdmetaData.newPwd //
            this.strongPassword = this.newPwdmetaData.pwdStrength.strength;

            //create formcontrols for new pwd - setPwd formGroup

            if (!this.qtnList) {
              this.errorMessage = this.forgotPwdmetaData.genErrors.qtns // "No questions"
              this.userName.focus;
            } else {
              this.errorMessage = null
              this.forgotPwdState = 1
            }

            //  call this api to send the 4 digit code to user email id

            this._service.sendAuthCode({ "user_name": this.userName })
              .pipe(takeUntil(this.$destroy))
              .subscribe((val: any) => {
                if (val) {
                  this.display = "02:00";
                  this.timer(2);
                  this.mat_spinner_forgotpwd_state = false;

                  this.toastservice.show({
                    text: val.status,
                    type: 'success'
                  })

                }
                // this.errorMessage= this.forgotPwdmetaData.genErrors.aCode // "Auth code sent"
              })
          }
        })
    } else {
      this.errorMessage = "Please enter user name";  //this.forgotPwdmetaData.genErrors.uname //
      this.userName.focus;
    }

  }
  get ans1() {
    return this.pwdVerify.get('ans1');
  }

  get ans2() {
    return this.pwdVerify.get('ans2');
  }

  get verificationCode() {
    return this.pwdVerify.get('verificationCode');
  }

  resend_click() {
    this.timerState = false;
    this.mat_spinner_forgotpwd_state = true;
    this.display = "";
    this._service.sendAuthCode({ "user_name": this.userName }).pipe(takeUntil(this.$destroy)).subscribe((val: any) => {
      if (val) {
        this.mat_spinner_forgotpwd_state = false
        this.toastservice.show({
          text: val.status,
          type: 'success'
        })
      } else {
        // this.errorMessage= this.forgotPwdmetaData.genErrors.aCode // "Auth code sent"
        this.timer(2);
      }
    })
  }

  timer(minute) {
    // return  new Promise((res,rej)=>{
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;
    const prefix = minute < 10 ? "0" : "";
    this.timeControler = setInterval(() => {
      seconds--;
      if (statSec != 0) {
        statSec--;
      } else {
        statSec = 59;
      }
      if (statSec < 10) {
        textSec = "0" + statSec;
      } else {
        textSec = statSec;
      }

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.timerState = true;
        clearInterval(this.timeControler);
      }
    }, 1000);
  }

  cancel_click() {
    clearInterval(this.timeControler)
    this.mat_spinner_forgotpwd_state = false;
    this.display = "";
    this.timerState = false
    this.errorMessage = null
    this.forgotPwdState = 0
    this.pwdVerify.reset()
  }

  load_setNewPassword() {
    this.pwdVerify.get("user_name").patchValue(this.userName)
    this.errorMessage = null
    if (this.pwdVerify.valid) {
      // call api to verify the SecretQA and auth code, only when the form is valid;
      this._service.userName = this.userName
      this._service.postSecretQA(this.pwdVerify.value)
        .pipe(takeUntil(this.$destroy))
        .subscribe((val: any) => {
          if (val.error) {
            this.showForgotError = true
            this.errorMessage = val.errmsg
          } else {
            this.errorMessage = null
            this.forgotPwdState = 2
            this.pwdVerify.reset()
          }
        })
    }
  }

  get newPwd() {
    return this.setPwd.get('newPwd')
  }

  get setPwdFC() {
    return this.setPwd.controls
  }

  get reEnterPwd() {
    return this.setPwd.get('reEnterPwd')
  }

  submit_click() {
    if (this.newPwd.valid && this.reEnterPwd.valid) {
      this._service.postNewpwd({ "user_name": this.userName, "pwd": this.newPwd.value }).pipe(takeUntil(this.$destroy)).subscribe((val: any) => {
        if (val.err) {
          this.showNewPassError = true;
          this.errorMessage = val.errmsg
        } else {
          this.newPwd.reset()
          this.reEnterPwd.reset()
          this.errorMessage = null
          this.forgotPwdState = 0
        }
      })
    }
  }
  changeCustomer(customer) {
    this._service.mat_spinner_login_state = true;

    this.access.accessToken({ "change_status": true, "customer": customer })
    this.errorMessage = '';
    setTimeout(() => {
      this._service.getMetaData({ "user_name": this.userName })
        .then(async metadata => {
          this.validationData = this._service.metaData.commonData.userDetailvalidationData
          this._service.getTimezone({ "user_name": this.userName }).toPromise()
            .then((timezone) => {
              this._service.timezone = timezone['timezone'];
              this._service.mat_spinner_login_state = false;
              this.router.navigateByUrl('home');
              // ----------------------//
            })
            .catch(err => {
              this._service.mat_spinner_login_state = false;
            })

        })
        .catch(err => {
          this._service.mat_spinner_login_state = false;
        })

    }, 200);
  }
  ngOnDestroy(): void {
    this.$destroy.next(true)
    this.$destroy.unsubscribe()
  }
}