
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RoutecrumpsService } from 'src/app/services/routecrumps.service';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { ChangeValidationComponent } from './change-validation/change-validation.component';
import { SettingsService } from '../settings.service';
import { SettingsVarService } from '../settings-var.service';
import { takeUntil } from 'rxjs/operators';
import { pipe, Subject } from 'rxjs';
import { AddCustomerComponent } from '../add-customer/add-customer.component';
import { KeyValue } from '@angular/common';
import { ToastService } from 'src/app/shared/custom-overlay/toast/toast.service';
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  mat_spinner_organizationList_state: boolean = false;
  mat_spinner_CustomerState: boolean = false;
  toasterMessage: string;
  searchCustomerList: string = ""
  constructor(
    private fb: UntypedFormBuilder,
    public loginService: UserLoginService,
    private rcrumpService: RoutecrumpsService,
    private matdialog: MatDialog,
    private settingsService: SettingsService,
    public settingsVarService: SettingsVarService,
    private toaster: ToastService

  ) { }
  $destroy: Subject<boolean> = new Subject()

  preview_state: boolean
  pages = {   //pagination
    presentPage: 1,
    itemPerPage: 10,
    length: 0
  };
  pagination = this.fb.group({ itemPerPage: [] }) //items per page form control

  metaData = this.loginService.metaData; // All meta data details as object
  nodata = this.loginService.metaData.alert.noDatamsg[1]
  // headers = [
  //   // { "disp": "S.NO", "value": "sno" },
    
  //   { "disp": "SERVICE PROVIDER", "value": "serviceProvider" },
  //   { "disp": "CUSTOMER", "value": "customerFullName" },
  //   { "disp": "Short Name", "value": "customerShortName" },
  //   { "disp": "TYPE", "value": "type" },
  //   { "disp": "LICENSE TYPE", "value": "licenseType" },
  //   { "disp": "LICENSE VALIDITY", "value": "licenseValidity" },
  //   { "disp": "CREATED ON", "value": "createdDt" },
  //   { "disp": "", "value": "edit_data" },

  // ]


  ngOnInit(): void {
    this.createCrumps()
    this.preview_state = false;
    this.load_organizationList()
    console.log(this.settingsVarService)
    this.getCustomerList().then((customerList) => {
      this.settingsVarService.organization_list = customerList
    }).catch((err) => {
      console.log("error")
    })
  }
  get itemPerPage() {
    return this.settingsVarService.organizationPagination;
  }

  getCustomerPlatform(){
    return new Promise((resolve, reject) => {
      this.settingsService.getCutomerPlatformList({"username":this.loginService.userName})
        .subscribe((data) => {
          resolve(data);
        },
          (error) => {
            reject(error);
          })
    })
  }


  addNewCustomer() {
    this.mat_spinner_CustomerState = true;

    
    this.getCustomerPlatform()
      .then((platform_list:any) => {
        this.mat_spinner_CustomerState = false;
        if(platform_list.isError){
          this.toasterMessage = "Customer platform get failed"
          this.settingsService.showToaster(this.toasterMessage, 'failure')
        }
        else{
          
          console.log("calling api result" ,platform_list)
          let dialogRef = this.matdialog.open(AddCustomerComponent,
            {
              width: "40%",
              height: "84vh",
              data: {"header":"Add Customer","service_provider":"Centiyent","buttonLabels": ["Submit", "Cancel"],"editdata": null,"platform_info":platform_list }, 
              autoFocus: false,
              disableClose: true
            }
          );
          dialogRef.afterClosed()
            .pipe(takeUntil(this.$destroy))
            .subscribe((dat: any) => {
              if (dat) {
                console.log("before load_organizationList ",dat)
                this.load_organizationList()
              }
        
          });
        }
      }).catch((err)=>{
        this.mat_spinner_CustomerState = false;
        console.log("calling api err" ,err)
        this.toasterMessage = "Customer platform get failed"
        this.settingsService.showToaster(this.toasterMessage, 'failure')
      })
    // let platform_info =  [
    //   {"assigned_status":false,"already_deployed":0,"platform_id":1,"platform":"windows","platform_disp":"Windows","deploy_status":true},
    //   {"assigned_status":false,"already_deployed":0,"platform_id":2,"platform":"edr","platform_disp":"EDR","deploy_status":false},
    //   {"assigned_status":false,"already_deployed":0,"platform_id":3,"platform":"edlp","platform_disp":"EDLP","deploy_status":false},
    //   {"assigned_status":false,"already_deployed":0,"platform_id":4,"platform":"waf","platform_disp":"UTM WAF","deploy_status":false},
    //   {"assigned_status":false,"already_deployed":0,"platform_id":5,"platform":"vpn","platform_disp":"UTM VPN","deploy_status":false},
    //   {"assigned_status":false,"already_deployed":0,"platform_id":6,"platform":"antispam","platform_disp":"UTM Antispam","deploy_status":false},
    //   {"assigned_status":false,"already_deployed":0,"platform_id":7,"platform":"o365_exchange","platform_disp":"0365-Exchange","deploy_status":false}
    // ]
   
    
  }



  getCustomerList() {
    return new Promise((resolve, reject) => {
      this.settingsService.getCustomers(this.loginService.userName).
        subscribe((data: any) => {
          resolve(data.customerList);
        },
          (error) => {
            reject(error);
          })
    })
  }

  pagination_click(direction) {
    if (this.settingsVarService.organization_pages.length > 1) {
      if (direction == "left") {
        if (this.settingsVarService.organization_pages.presentPage > 1) {
          this.settingsVarService.organization_pages.presentPage--;
          this.load_organizationList();
        }
      } else {
        if (this.settingsVarService.organization_pages.presentPage < this.settingsVarService.organization_pages.length) {
          this.settingsVarService.organization_pages.presentPage++;
          this.load_organizationList();
        }
      }

    }
  }
  load_organizationList() {
    this.mat_spinner_organizationList_state = true
    this.settingsService.getCustomers(this.loginService.userName)
      .pipe(takeUntil(this.$destroy))
      .subscribe((val) => {
        this.mat_spinner_organizationList_state = false
        this.settingsVarService.organization_list = [...val['customerList']];
        console.log(this.itemPerPage.value)
        this.settingsVarService.organization_pages.length = Math.ceil(this.settingsVarService.organization_list.length / this.itemPerPage.value)
      }, (err) => {
        this.mat_spinner_organizationList_state = false
      })
  }
  createCrumps() {
    this.rcrumpService.route_crumps[1] = {
      disp_str: "Organization",
      routeTo: "organization",
      callback: null,
      isParent: false
    };
    this.rcrumpService.route_crumps.length = 2;
  }
  changeCustomer(customer, event) {
    event.stopPropagation();
    if(!customer.isCurrent){
      const addeventsdialogRef = this.matdialog.open(ChangeValidationComponent, {
        width: "30vw", height: "30vh", data: { "customer": customer.customerFullName }, autoFocus: false,
        disableClose: true
      })
    }
    
    this.loginService.$customerChanged.next(customer)
  }


  getEditCustomer(customerfullname:string){
    console.log("getEditCustomer",customerfullname)
    return new Promise((resolve, reject) => {
      this.settingsService.getEditCutomer({"fullname":customerfullname})
        .subscribe((data) => {
          resolve(data);
        },
          (error) => {
            reject(error);
          })
    })
  }


  editCustomer(customer, event) {
    console.log("editCustomer",customer)
    this.mat_spinner_CustomerState = true;
    this.getEditCustomer(customer.customerFullName)
      .then((result:any) => {

        this.mat_spinner_CustomerState = false;
        if(result.isError){
          this.toasterMessage = "Customer edit get failed"
          this.settingsService.showToaster(this.toasterMessage, 'failure')
        }
        else{
          console.log("calling edit get api result" ,result)
          let dialogRef = this.matdialog.open(AddCustomerComponent,
            {
              width: "40%",
              height: "84vh",
              data: {"header":"Edit Customer","service_provider":customer.serviceProvider,"buttonLabels": ["Submit", "Cancel"],"editdata": true,"customerinfo":{"old_fullname":result.old_fullname,"fullname":result.fullname,"shortname":result.shortname,"domain_name":result.domain_name},"platform_info":result.platforms }, 
              autoFocus: false,
              disableClose: true
            }
          );
          dialogRef.afterClosed()
            .pipe(takeUntil(this.$destroy))
            .subscribe((dat: any) => {
              if (dat) {
                console.log("before load_organizationList ",dat)
                this.load_organizationList()
              }
          });
        }
        
      }).catch((err)=>{
        this.mat_spinner_CustomerState = false;
        console.log("calling edit get api err" ,err)
        this.toasterMessage = "Customer edit get failed"
        this.settingsService.showToaster(this.toasterMessage, 'failure')
      })
  }
    //unsort the key value from the array of objects
    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
      return 0;
    }
}




// import { Component, OnInit } from '@angular/core';
// import { UntypedFormBuilder } from '@angular/forms';
// import { MatDialog } from '@angular/material/dialog';
// import { RoutecrumpsService } from 'src/app/services/routecrumps.service';
// import { UserLoginService } from 'src/app/user-login/user-login.service';
// import { ChangeValidationComponent } from './change-validation/change-validation.component';
// import { SettingsService } from '../settings.service';
// import { SettingsVarService } from '../settings-var.service';
// import { takeUntil } from 'rxjs/operators';
// import { Subject } from 'rxjs';
// @Component({
//   selector: 'app-organization',
//   templateUrl: './organization.component.html',
//   styleUrls: ['./organization.component.scss']
// })
// export class OrganizationComponent implements OnInit {
//   mat_spinner_organizationList_state: boolean = false;
//   constructor(
//     private fb: UntypedFormBuilder,
//     public loginService: UserLoginService,
//     private rcrumpService: RoutecrumpsService,
//     private matdialog: MatDialog,
//     private settingsService: SettingsService,
//     public settingsVarService: SettingsVarService

//   ) { }
//   $destroy: Subject<boolean> = new Subject()

//   preview_state: boolean
//   pages = {   //pagination
//     presentPage: 1,
//     itemPerPage: 10,
//     length: 0
//   };
//   pagination = this.fb.group({ itemPerPage: [] }) //items per page form control

//   metaData = this.loginService.metaData; // All meta data details as object
//   nodata = this.loginService.metaData.alert.noDatamsg[1]
//   headers = [
//     // { "disp": "S.NO", "value": "sno" },
//     { "disp": "CREATED ON", "value": "createdDt" },
//     { "disp": "SERVICE PROVIDER", "value": "serviceProvider" },
//     { "disp": "CUSTOMER", "value": "customerFullName" },
//     // { "disp": "Short Name", "value": "customerShortName" },
//     // { "disp": "TYPE", "value": "type" },
//     { "disp": "LICENSE TYPE", "value": "licenseType" },
//     { "disp": "LICENSE VALIDITY", "value": "licenseValidity" },
//     { "disp": "", "value": "edit_data" },

//   ]


//   ngOnInit(): void {
//     this.createCrumps()
//     this.preview_state = false;
//     this.load_organizationList()
//     // this.getCustomerList().then((customerList) => {
//     //   this.settingsVarService.organization_list = customerList
//     // }).catch((err) => {
//     //   console.log("error")
//     // })
//   }
//   get itemPerPage() {
//     return this.settingsVarService.organizationPagination;
//   }
//   getCustomerList() {
//     return new Promise((resolve, reject) => {
//       this.settingsService.getCustomers(this.loginService.userName).
//         subscribe((data: any) => {
//           resolve(data.customerList);
//         },
//           (error) => {
//             reject(error);
//           })
//     })
//   }

//   pagination_click(direction) {
//     if (this.settingsVarService.organization_pages.length > 1) {
//       if (direction == "left") {
//         if (this.settingsVarService.organization_pages.presentPage > 1) {
//           this.settingsVarService.organization_pages.presentPage--;
//           this.load_organizationList();
//         }
//       } else {
//         if (this.settingsVarService.organization_pages.presentPage < this.settingsVarService.organization_pages.length) {
//           this.settingsVarService.organization_pages.presentPage++;
//           this.load_organizationList();
//         }
//       }

//     }
//   }
//   load_organizationList() {
//     this.mat_spinner_organizationList_state = true
//     this.settingsService.getCustomers(this.loginService.userName)
//       .pipe(takeUntil(this.$destroy))
//       .subscribe((val) => {
//         this.mat_spinner_organizationList_state = false
//         this.settingsVarService.organization_list = [...val['customerList']];
//         console.log(this.itemPerPage.value)
//         this.settingsVarService.organization_pages.length = Math.ceil(this.settingsVarService.organization_list.length / this.itemPerPage.value)
//       }, (err) => {
//         this.mat_spinner_organizationList_state = false
//       })
//   }
//   createCrumps() {
//     this.rcrumpService.route_crumps[1] = {
//       disp_str: "Organization",
//       routeTo: "organization",
//       callback: null,
//       isParent: false
//     };
//     this.rcrumpService.route_crumps.length = 2;
//   }
//   changeCustomer(customer, event) {
//     event.stopPropagation();
//     if(!customer.isCurrent){
//       const addeventsdialogRef = this.matdialog.open(ChangeValidationComponent, {
//         width: "30vw", height: "30vh", data: { "customer": customer.customerFullName }, autoFocus: false,
//         disableClose: true
//       })
//     }
    
//     // this.loginService.$customerChanged.next(customer)
//   }
// }
