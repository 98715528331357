import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutecrumpsService } from '../services/routecrumps.service';
import { UserLoginService } from '../user-login/user-login.service';
import { MatDialog } from '@angular/material/dialog';
import { NewPwdComponent } from '../user-login/new-pwd/new-pwd.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private rcrumpService: RoutecrumpsService,
    private userloginService: UserLoginService,
    private matdialog: MatDialog
  ) { }

  label = this.rcrumpService.label;
  applist = this.rcrumpService.applist;

  ngOnInit(): void {
    console.log("applist====================>",this.applist)
    if (this.userloginService.forcePwd) {
      let newPwd = this.matdialog.open(NewPwdComponent, { width: "50%", height: "40vh", autoFocus: false });
      newPwd.afterClosed().subscribe((data) => {
        if (data) {
        }
      })
    }
    this.rcrumpService.homeIcon.isSelected = true
    this.rcrumpService.route_crumps[0] = {
      disp_str: "Home",
      routeTo: "home",
      callback: null,
      isParent: false
    }
    this.rcrumpService.route_crumps.length = 1;
    this.rcrumpService.resetMenuList()
  }

  chip_click(module: any) {
    if (module.isSelectable && module.url) {
      this.rcrumpService.homeIcon.isSelected = false
      this.rcrumpService.resetMenuList()
      module["isSelected"] = true
      // this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
      console.log("this.router.navigateByUrl(module.url)", module.url)
      this.router.navigateByUrl(module.url)
    }
  }

}
