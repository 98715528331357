import { Component, Inject } from '@angular/core';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../settings.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastService } from 'src/app/shared/custom-overlay/toast/toast.service';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { FullNameValidator } from '../validators/customernameAsync.validator';


@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent {
  mat_spinner_role_state: boolean
  submitState:boolean = false;
  platform_info:any[];
  $destroy: Subject<boolean> = new Subject();
  toasterMessage: string;
  inputBoxEmitChar = this._service.metaData.commonData.specialCharList.charList;
  customer_metadata_infos = {
    "labels":["Customer Name","Short Name","Domain Name"],
    "placeholder":["Enter fullname","Enter shortname","Enter domain name"],
    "headers":["Platform","install","Deploy"],
    "error":{
      "fullname":{
        "required":"Customer name is required",
        "customerNameExists":"Customer name already exits"
      },
      "shortname":{
        "required":"Short name is required",
      },
      "domain_name":{
        "required":"Domain name is required"
      },
      "platforms":{
        "required":"platform is required"
      }
    }
  }

  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<AddCustomerComponent>,
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private toaster: ToastService,
    private _service: UserLoginService
  ){}


  addCustomerForm:FormGroup;
  initialFormArrayValues:any[];// Store initial FormArray values
  addPlatformForm:FormGroup;
  shortnameValueChangeState:boolean = false
  domainnameValueChangeState:boolean = false
  ngOnInit(): void {
    this.platform_info=this.data.platform_info
    if(this.data.editdata){
      
      this.addCustomerForm = this.fb.group({
        fullname: [null, [Validators.required]],
        shortname: [this.data.customerinfo.shortname, Validators.required],
        domain_name: [this.data.customerinfo.domain_name, Validators.required],
        platforms: this.fb.array([])
      })
      this.fullname.disable();
      this.fullname.setValue(this.data.customerinfo.fullname)
      this.shortname.valueChanges.subscribe(val=>{
        if(val != this.data.customerinfo.shortname){
          this.shortnameValueChangeState = true
        }
        else{
          this.shortnameValueChangeState = false
        }
      })


      this.domain_name.valueChanges.subscribe(val=>{
        if(val != this.data.customerinfo.domain_name){
          this.domainnameValueChangeState = true
        }
        else{
          this.domainnameValueChangeState = false
        }
      })
    }
    else{
      this.addCustomerForm = this.fb.group({
        fullname: ['', [Validators.required],[FullNameValidator.createValidator(this.settingsService)]],
        shortname: [null, Validators.required],
        domain_name: [null, Validators.required],
        platforms: this.fb.array([])
      })
      
    }
    
    this.platform_info.forEach((elem:any, index:number) => {
      this.platforms.push(this.fb.group({
        platform_id: [elem.platform_id, Validators.required],
        assigned_status: [{'value':elem.assigned_status,disabled: elem.already_deployed?true:false}, Validators.required],
        platform: [elem.platform, Validators.required],
        platform_disp:[{'value':elem.platform_disp,disabled: true}, Validators.required],
        deploy_status:[{'value':elem.deploy_status,disabled: (elem.assigned_status===false || elem.already_deployed===1)?true:false}, Validators.required],
        already_deployed:[elem.already_deployed, Validators.required]
      }))
    });
    this.initialFormArrayValues = this.addCustomerForm.get('platforms').value;
  
  }

  get fullname(){
    return this.addCustomerForm.get('fullname')
  }

  get shortname(){
    return this.addCustomerForm.get('shortname')
  }

  get domain_name(){
    return this.addCustomerForm.get('domain_name')
  }

  get platforms() {
    return this.addCustomerForm.get('platforms') as FormArray;
  }

  
  checkdepoly_status(index:number){
    if(this.platforms.at(index).value.assigned_status){
      this.platforms.at(index).get("deploy_status").enable()
    }
  }

  checkassign_status(index:number){
    let current_assigned_status = this.platforms.at(index).value.assigned_status?false:true
    this.platforms.at(index).get("assigned_status").setValue(current_assigned_status)
    if(this.platforms.at(index).value.assigned_status){
      this.platforms.at(index).get("deploy_status").enable()
      this.platforms.at(index).get("deploy_status").setValue(false)
    }
    else{
      this.platforms.at(index).get("deploy_status").setValue(false)
      this.platforms.at(index).get("deploy_status").disable()
    }

    this.platforms.valueChanges.subscribe(values => {
      // console.log(JSON.stringify(values),JSON.stringify(this.initialFormArrayValues),JSON.stringify(values) === JSON.stringify(this.initialFormArrayValues))
      if (JSON.stringify(values) === JSON.stringify(this.initialFormArrayValues)) {
        this.platforms.markAsPristine()
        this.platforms.markAsUntouched()
      }
    });
  }
  close() {
    this.ref.close()
  }
  cancel() {
    this.ref.close()
  }

  onSubmitClick(){
    this.submitState = true
    if(this.addCustomerForm.valid){
      this.fullname.enable()
      this.mat_spinner_role_state = true
      let customer_info = this.addCustomerForm.value 
      customer_info.licence_type = "Standard"
      customer_info.licence_validity = "31 Dec 2030"
      let platform_len = this.addCustomerForm.value.platforms.length
     
      customer_info.platforms = []
      if(this.addCustomerForm.get('platforms').dirty){
        for (var i = 0; i < platform_len; i++) {
          console.log(this.platforms.at(i).dirty,this.platforms.at(i).value.assigned_status)
          if(this.data.editdata){
            if(this.platforms.at(i).dirty ){
              let platform_info = this.platforms.at(i).value
              if(!this.platforms.at(i).value.assigned_status){
                platform_info.deploy_status = false
              }
              console.log(platform_info)
              platform_info.platform_disp = this.platforms.at(i).get('platform_disp').value
              customer_info.platforms.push(platform_info)
            }
          }
          else{
            if(this.platforms.at(i).dirty && this.platforms.at(i).value.assigned_status ){
              let platform_info = this.platforms.at(i).value
              platform_info.platform_disp = this.platforms.at(i).get('platform_disp').value
              customer_info.platforms.push(platform_info)
            }
          }
        }
      }
      if(customer_info.platforms.length>0 || this.domainnameValueChangeState || this.shortnameValueChangeState){
        if(this.data.editdata){
          customer_info.old_fullname = this.data.customerinfo.old_fullname
          customer_info['shortnamechange'] = this.shortnameValueChangeState
          customer_info['domainnamechange'] = this.domainnameValueChangeState
          this.settingsService.postEditCustomer(customer_info)
          .pipe(takeUntil(this.$destroy))
          .toPromise()
          .then((result:any) => {
            if(result.status != 1){
              this.toasterMessage = "Edit customer post failed"
              this.settingsService.showToaster(this.toasterMessage, 'failure')
            }
            else{
              this.toasterMessage = "Edit customer successfuly post"
              this.settingsService.showToaster(this.toasterMessage, 'success')
            }
            this.mat_spinner_role_state = false
            this.submitState = false
            
            this.ref.close(result)
          })
          .catch((err)=>{
            this.mat_spinner_role_state = false
            this.submitState = false
            // this.toasterMessage = "Customer post failed"
            // this.settingsService.showToaster(this.toasterMessage, 'failure')
            this.ref.close({"status":0})
          })

        }
        else{
          this.settingsService.postNewCustomer(customer_info)
          .pipe(takeUntil(this.$destroy))
          .toPromise()
          .then((result:any) => {
            if(result.status != 1 ){
              this.toasterMessage = "Customer post failed"
              this.settingsService.showToaster(this.toasterMessage, 'failure')
            }
            else{
              this.toasterMessage = "Customer successfuly post"
              this.settingsService.showToaster(this.toasterMessage, 'success')
            }
            this.mat_spinner_role_state = false
            this.submitState = false
            
            this.ref.close(result)
          })
          .catch((err) => {
            this.mat_spinner_role_state = false
            this.submitState = false
            // this.toasterMessage = "Customer post failed"
            // this.settingsService.showToaster(this.toasterMessage, 'failure')
            this.ref.close({"status":0})
          })
        }
      }

    }
    this.mat_spinner_role_state = false

  }
  ngOnDestroy(): void {
    this.$destroy.next(true)
  }
}
