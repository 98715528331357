import { AbstractControl, ValidatorFn } from "@angular/forms";

export function VerifyEmail():ValidatorFn {
    
    return (control:AbstractControl): {[key:string]:any} | null => {
        const mailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
        if(control.value){
            if(!mailRegex.test(control.value)){
                return {email:true}
            }
        }
        return null
    }   
}