<div class="container">
  <header class="header-wrapper">
    <div class="header">
      <div class="logo" routerLink="/home">
        <img class="siem" src="assets/newLogo5_1.svg">
      </div>
      <div class="route-crumps">
        <ng-container *ngFor="let crump of crumpService.route_crumps; index as i; let lindex = last;let findex = first">
          <ng-container *ngIf="!findex">
            <p [ngClass]="lindex?'':'route-clickable'" (click)="crump_click(crump,i)">{{crump.disp_str}}</p>
            <p *ngIf="!lindex"> > </p>
          </ng-container>
        </ng-container>
      </div>
      <div class="menu-user">
        <!-- <img (click)="openOverlay_click(userMenuRef,userMenuOrigin)" class="profile" src="assets/userprofile.svg"
          matTooltip="Profile"> -->

        <img class="menu-icon" src="assets/bell.svg">
        <img class="menu-icon" src="assets/apps.svg">
      </div>
      <div class="UserProfile" (click)="openOverlay_click(userMenuRef,userMenuOrigin)" #userMenuOrigin>
        <span class="username-icon">{{_service.userFName.substring(0, 1) | uppercase}}{{_service.userLName.substring(0,
          1) | uppercase}}</span>
        <div class="main">
          <!-- <span class="username">Welcome {{_service.userFName}}</span> -->
          <span class="username">Hello {{_service.userFName | titlecase}}</span>
          <span class="customer">@{{_service.customer}}</span>
        </div>
      </div>
    </div>

  </header>
  <div class="body-wrapper">
    <ng-container *ngIf="mat_spinner_userProfile_state">
      <div class="body-bottom-spinner">
        <mat-spinner class="spinner" diameter=20></mat-spinner>
      </div>
    </ng-container>
    <div class="body-wrapper-navbar">
      <div class="navbar-icon-container">
        <div [ngClass]="crumpService.homeIcon.isSelected?'module-selected':'module-deselected'"
          (click)="navIconclick(crumpService.homeIcon)">
          <!-- <div class="module-deselected" (click)="navIconclick(crumpService.homeIcon)"> -->
          <div class="menu-panel-icon">
            <img [src]="crumpService.homeIcon.img_url" class="module-active">
          </div>
          <span
            [ngClass]="crumpService.homeIcon.isSelectable? 'module-active-label menu-label' :'module-inactive-label menu-label'">{{crumpService.homeIcon.label}}</span>
        </div>
      </div>
      <ng-container *ngFor="let applist of crumpService.applist">
        <span class="divider"></span>

        <div class="navbar-icon-container">
          <ng-container *ngFor="let nav of applist">
            <!-- <div [ngClass]="nav.isSelected?'module-selected':'module-deselected'" (click)="navIconclick(nav)"> -->
            <div
              [ngClass]="nav.isSelected && nav.catagory_order? ['module-selected','category-'+nav.catagory_order]:'module-deselected'"
              (click)="navIconclick(nav)">
              <div class="menu-panel-icon">
                <!-- <img  [ngClass]="nav.isSelectable? 'module-active' :'module-inactive'" [src]="nav.img_url"> -->
                <img class="icon-default-color"
                  [ngClass]="nav.isSelectable && nav.catagory_order? 'category-icon-'+nav.catagory_order:'module-inactive'"
                  [src]="nav.img_url">
              </div>
              <span
                [ngClass]="nav.isSelectable? 'module-active-label menu-label' :'module-inactive-label menu-label'">{{nav.label}}</span>

            </div>
          </ng-container>
        </div>

      </ng-container>
    </div>
    <div class="body-wrapper-content">
      <router-outlet></router-outlet>
    </div>

    <ng-template #userMenuRef>
      <div class="user-menu-container">
        <div class="user-menu-wrapper">
          <div class="row-1 menu" (click)="openMyProfile_click()">
            <i class="my-profile"></i><span>My Profile</span>
          </div>
          <div class="row-3 menu" (click)="openResetPassword_click()">
            <i class="reset-password"></i><span>Reset Password</span>
          </div>
          <div class="menu" (click)="openOrganization_click()">
            <i class="changeCustomer"></i><span>Change customer</span>
          </div>
          <div class="row-4 menu" (click)="logout()">
            <i class="logout"></i><span>Logout</span>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <!-- <footer class="footer-wrapper"> -->
    <!-- <p class="copyright">&copy;{{footer?.copyright}}</p>
    <p class="version">{{footer?.version}}</p> -->
  <!-- </footer> -->
</div>